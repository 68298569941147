import { DashboardService } from './../shared/service/dashboard.service';
import { Component, OnInit,  ViewChild, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { ElementRef } from '@angular/core';
import { FormGroup,FormBuilder } from '@angular/forms';
import { Constant } from '../shared/constant/constant';
declare var jquery: any;
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'] ,

})
export class DashboardComponent implements OnInit ,AfterViewInit {
  
  // Chart
  private numV:number;
  private numIn:number;
  private numOut:number;
  private numInt:number;
  private numAll:any;
  private monthIn :any;
  private monthOut:any;
  private labelIn : Array<any> = [];
  private dataChartIn: Array<any> = [];
  private labelOut : Array<any> = [];
  private dataChartOut:Array<any> = [];

  chartIn : any;
  chartOut :any;

  private chartOptions :any;

  // End Chart


  // ModelVisit 
  visitTitle:any;
  visitStatus:any;
  visitList : Array<any>; 
  visitRecord : Array<any>; 
  //End Model Visit

  @ViewChild('canvasIn',{static:false}) canvasIn: ElementRef<HTMLCanvasElement>;
  public ctxIn: CanvasRenderingContext2D;

  @ViewChild('canvasOut',{static:false}) canvasOut: ElementRef<HTMLCanvasElement>;
  public ctxOut: CanvasRenderingContext2D;
  constructor(private dashbordService : DashboardService , private fb: FormBuilder ,private constant : Constant) {    
   }
  ngAfterViewInit(): void {

    this.chartOptions = {
      responsive: true,
      legend:{
        display :true
      },
      maintainAspectRatio: false,
      scales: {
          xAxes: [{
              display: true,
              gridLines: {
                  color: "#f3f3f3",
                  drawTicks: false,
              },
              scaleLabel: {
                  display: true,
              },
              ticks: {
                padding: 10
            }
          }],
          yAxes: [{
              display: true,
              gridLines: {
                  color: "#f3f3f3",
                  drawTicks: false,
              },
              scaleLabel: {
                  display: true,
              },
              ticks: {
                padding: 10
            }
          }]
      },
      title: {
          display: false,
          text: 'title'
      },
      animation: {
          onComplete: function () {
              var chartInstance = this.chart;
              var ctx = chartInstance.ctx;
              ctx.textAlign = "center";

              Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  Chart.helpers.each(meta.data.forEach(function (bar, index) {
                      ctx.fillText(dataset.data[index], bar._model.x, bar._model.y - 10);
                  }),this);
              }),this);
          }
      }
    };

    this.ctxIn = this.canvasIn.nativeElement.getContext('2d');
    this.ctxOut = this.canvasOut.nativeElement.getContext('2d');
    this.monthIn = '1D';
    this.monthOut = '1D';
    this.bindChartsIn(false);
    this.bindChartsOut(false);

    this.getChartIn();
    this.getChartOut();
  }
  ngOnInit() {

      this.monthIn = '1D';
      this.monthOut = '1D';

      this.getAllStatusCount();
  }

  onMonthInChange(value)
  {
    this.monthIn = value;
    this.getChartIn();
  }

  onMonthOutChange(value)
  {
    this.monthOut = value;
    this.getChartOut();
  }

  reChartIn(){
    console.log('reload-in');
    this.getChartIn();
  }

  reChartOut()
  {
    console.log('reload-out');
    this.getChartOut();
  }

  bindChartsIn(isUpdate)
  {
    if(!isUpdate)
    {
        // Chart Data
        var chartData = {
            labels: this.labelIn,
            datasets: [{
                type: 'bar',
                label: "จำนวนเข้าพื้นที่ ",
                data: this.dataChartIn,
                backgroundColor: "#00A5A8",
                borderColor: "transparent",
                borderWidth: 2
            }]
        };

        var config = {
            type: 'bar',
            // Chart Options
            options : this.chartOptions,

            data : chartData
        };
        this.chartIn = new Chart(this.ctxIn, config);
    }
    else
    {
        this.removeChartData(this.chartIn);
        this.updateChartData(this.chartIn,this.labelIn,this.dataChartIn);
    }

  }

  bindChartsOut(isUpdate)
  {
     if(!isUpdate)
     {

          // Chart Data
          var chartData = {
              labels: this.labelOut,
              datasets: [{
                  type: 'bar',
                  label: "จำนวนออกพื้นที่ ",
                  data: this.dataChartOut,
                  backgroundColor: "#FA8E75",
                  borderColor: "transparent",
                  borderWidth: 2
              }]
          };

          var config = {
              type: 'bar',
              // Chart Options
              options : this.chartOptions,

              data : chartData
          };

        this.chartOut = new Chart(this.ctxOut, config);
     }
     else
     {
      this.removeChartData(this.chartOut);
      this.updateChartData(this.chartOut,this.labelOut,this.dataChartOut);
     }
  }

  getAllStatusCount(){
    this.dashbordService.getCountAllStatus().subscribe(data=>{
          this.numAll = data;
          this.numV = this.numAll[0].prop1;
          this.numIn = this.numAll[1].prop1;
          this.numOut =this.numAll[2].prop1;
          this.numInt =this.numAll[3].prop1;
    });
  }

  getChartIn()
  {
     this.dashbordService.getCharts("in",this.monthIn).subscribe(data=>{
      this.dataChartIn =[];
      this.labelIn = [];
      data.forEach(obj => {
          Object.entries(obj).forEach(([key, value]) => {
              if(key =='time_in')
                this.labelIn.push(value+".00 น.   ");
              if(key =='_count')
                this.dataChartIn.push(value);
          });
      });
      this.updateChartData(this.chartIn,this.labelIn,this.dataChartIn);
     });


  }

  getChartOut()
  {
    this.dashbordService.getCharts("out",this.monthOut).subscribe(data=>{
      this.dataChartOut =[];
      this.labelOut = [];
      data.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
              if(key =='time_out')
                this.labelOut.push(value+".00 น.   ");
              if(key =='_count')
                this.dataChartOut.push(value);
          });
      });
      this.updateChartData(this.chartOut,this.labelOut,this.dataChartOut);
    });
  }

  updateChartData(chart, label, data) {
    chart.data.labels = label;
    chart.data.datasets.forEach((dataset) => {
        dataset.data =data;
    });
    chart.update();
}

  removeChartData(chart) {
    chart.data.labels = [] ;
    chart.data.datasets.forEach((dataset) => {
        dataset.data =[];
    });
    chart.update();
}
  openStatusDetail(status , caption)
  {
    $('.loading').show();
    this.visitStatus = status;
    this.visitTitle  = caption;   
    $("#visitor_table").DataTable().clear().destroy();    
    setTimeout(() => {
      $('#visitor_table').DataTable({
      });
    }, 10); 
    this.loadVisitor(status);
    $('#modal-view-visit').modal('show');
    $('.loading').hide();
  }

  loadVisitor(status)
  {
    
    this.visitList = [];
    this.visitStatus = status;
    let criteria = {
      "status" : status
    }    
    this.dashbordService.getVisitStatus(criteria).subscribe(data => { 
      this.visitList = data;
      //console.log(data);
    }, error => {      
      
      this.failDialog(error);
    });
  }


  changeVisitStatus(visitTypeId)
  {
    $('.loading').show();
    $("#visitor_table").DataTable().clear().destroy();
    if(!visitTypeId){
      console.log("Not select visitype id");     
      $('.loading').hide();    
      return;
    }
    //console.log("VisitTypeId >> "+visitTypeId);
    let criteria = {
      "status": this.visitStatus,
      "visitorTypeId": visitTypeId
    }

    this.visitRecord = [];
    this.dashbordService.getVisitorRecord(criteria).subscribe(data => { 
      this.visitRecord = data;
      // console.log(data); 
      setTimeout(() => {
        $('#visitor_table').DataTable({
        });
        $('.loading').hide();   
      }, 10); 
     
    }, error => {      
      this.failDialog(error);
      $('.loading').hide();   
    });   
  }

  openInfo(id){
    // customer_id
    let customerId = localStorage.getItem('customer_id');
    window.open(this.constant.API_ENDPOINT + "/visitor-info-transaction/detail/pdf/" + id +"/customer/" + customerId, '_blank');
  }

  formatTime(time)
  {
    if(!time)return;

    if(time.length >= 19)
      return time.substring(0,19);
    return '';
  }

  failDialog(msg){
    $('.loading').hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })

  }
  

}
