import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LogoComponent } from './logo.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [LogoComponent],
  exports: [
    LogoComponent
  ],
  providers: [
    
  ]
})
export class LogoModule { }
