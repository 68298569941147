import { Component } from '@angular/core';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'layout-menu-left',
  templateUrl: './menu-left.component.html'
})


export class MenuLeftComponent { 
  authenMenbarber;
  userRole
  public isCustomerCollapsed = true;
  public isBranchCollapsed = true;
  public isSettingCollapsed = true;
  public isActive = true;
  constructor( 
  ) { 
  }

  ngOnInit() {
    this.authenMenbarber = JSON.parse(localStorage.getItem('authenMenbarberToken'));
    this.userRole = localStorage.getItem("user_role");
  }

  logoutUser() {
    localStorage.clear();
    localStorage.removeItem('authenMenbarberToken');

    window.location.href = "/";
  }

}
