import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeeAddComponent } from './employee_add.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [EmployeeAddComponent],
  exports: [
    EmployeeAddComponent
  ],
  providers: [
  ]
})
export class EmployeeAddModule { }
