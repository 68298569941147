import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { BackgroundPatternVisitorService, UploadFilesService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-background-pattern-visitor',
  templateUrl: './background-pattern-visitor.component.html',
  styleUrls: ['./background-pattern-visitor.component.css']
})
export class BackgroundPatternVisitorComponent implements OnInit { 

backgroundPatternVisitorList = new Array();
backgroundPatternVisitor = {"name":"","description":""};
addForm: FormGroup;
editForm: FormGroup;
submitted_add = false;
submitted_edit = false;
currentDepartmentId;
accessToken;
fileName = 'Choose file';

  constructor(private fb: FormBuilder,
    private uploadService: UploadFilesService,
    private backgroundPatternVisitorService: BackgroundPatternVisitorService) { 
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'type': ['', Validators.required],
      'description': [''],
      'file': [''],
      'file_manager_id': ['', Validators.required]
    });

    this.editForm = fb.group({
      'name': ['', Validators.required],
      'type': ['', Validators.required],
      'description': [''],
      'file': [''],
      'file_manager_id': ['', Validators.required],
      'id': ['', Validators.required]
    });

    this.accessToken = 'Bearer ' + localStorage.getItem('access_token');
  }

  ngOnInit() { 
    this.getAllBackgroundPatternVisitor();
  }

  fileManager;
  progressInfos = [];
  addSelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "background-pattern-visitor");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.addForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }

  editelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "background-pattern-visitor");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.editForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }

  getAllBackgroundPatternVisitor(){
    $("#background_pattern_visitor_table").DataTable().clear().destroy();
    this.backgroundPatternVisitorList = [];
    this.backgroundPatternVisitorService.getBackgroundPatternVisitor().subscribe(data=>{
      this.backgroundPatternVisitorList = data;
      console.log(data);
      setTimeout(() => {
        $('#background_pattern_visitor_table').DataTable({
        });
      }, 10);
    });
  }

  openAddBackgroundPatternVisitor(){
    this.fileManager = "";
    this.progressInfos = [];
    this.fileName = 'Choose file';
    this.addForm.patchValue({
      name: "",
      type: "",
      description: "",
      file_manager_id: "",
      file: ""
    });
    $('#modal-add').modal('show');
  }

  addBackgroundPatternVisitor(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    console.log(value);
    this.backgroundPatternVisitorService.addBackgroundPatternVisitor(value).subscribe(data => {
      this.submitted_add = false;
      $('#modal-add').modal('hide');
      this.getAllBackgroundPatternVisitor();
      this.successDialog();
    }, error => {
      $('#modal-add').modal('hide');
      this.failDialog(error);
    });
  }

  openEditBackgroundPatternVisitor(id){
    this.progressInfos = [];
    this.currentDepartmentId = id;
    this.backgroundPatternVisitorService.getBackgroundPatternVisitorById(id).subscribe(data => {
      this.backgroundPatternVisitor = data;
      this.fileManager = {url:this.backgroundPatternVisitor['url']};
      this.fileName = this.backgroundPatternVisitor['name_display'];
      this.editForm.patchValue({
        id: this.backgroundPatternVisitor['id'],
        name: this.backgroundPatternVisitor['name'],
        type: this.backgroundPatternVisitor['type'],
        description: this.backgroundPatternVisitor['description'],
        file_manager_id: this.backgroundPatternVisitor['file_manager_id'],
        file: ""
      });
      $('#modal-edit').modal('show');
    });
  }

  updateBackgroundPatternVisitor(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    console.log(value);
    this.backgroundPatternVisitorService.updateBackgroundPatternVisitor(value.id, value).subscribe(data => {
      this.submitted_edit = false;
      $('#modal-edit').modal('hide');
      this.getAllBackgroundPatternVisitor();
      this.successDialog();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  openDeleteBackgroundPatternVisitor(id){
    this.currentDepartmentId = id;
    $('#modal-remove').modal('show');
  }

  deleteBackgroundPatternVisitor(){
    this.backgroundPatternVisitorService.deleteBackgroundPatternVisitor(this.currentDepartmentId).subscribe(data => {
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.getAllBackgroundPatternVisitor();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
