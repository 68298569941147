import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterCardComponent } from './register.component';
import { Daterangepicker } from 'ng2-daterangepicker';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Daterangepicker
  ],
  declarations: [RegisterCardComponent],
  exports: [
    RegisterCardComponent
  ],
  providers: [
  ]
})
export class RegisterCardModule { }
