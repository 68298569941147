import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private constant: Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
  }

  uploadExcelFile(formData: FormData,card_visitor_template_id,sheet_name): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.constant.API_MANAGE_ENDPOINT}/excel/upload?card_visitor_template_id=${card_visitor_template_id}&sheet_name=${sheet_name}`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        'user_id': localStorage.getItem('customer_id')
        })
    });
    return this.http.request(req);
  }

  upload(formData: FormData): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.constant.API_ENDPOINT}/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        })
    });
    return this.http.request(req);
  }

  getFiles(id): Observable<any> {
    return this.http.get<any>(`${this.constant.API_ENDPOINT}/upload/${id}`, this.httpOptions);
  }

  download(url): Observable<any> {
    return this.http.get<any>(url, this.httpOptions);
  }
  

  remoteFile(id): Observable<any> {
    return this.http.delete<any>(`${this.constant.API_ENDPOINT}/upload/${id}`, this.httpOptions);
  }

  // Error handling
  handleError(error) {
  console.log(error);
  let errorMessage = '';
  if(error.error && error.error.data) {
    errorMessage = error.error.data.error_message;
  }
  return throwError(errorMessage);
  }
}