import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ImportDataCardRegisterComponent } from './import-data-card-register.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule
  ],
  declarations: [ImportDataCardRegisterComponent],
  exports: [
    ImportDataCardRegisterComponent
  ],
  providers: [
    
  ]
})
export class ImportDataCardRegisterModule { }
