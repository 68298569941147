import {NgModule} from '@angular/core'
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BacklistService} from '../shared/service/backlist.service';
import {BacklistComponent } from './backlist.component';
import { Daterangepicker } from 'ng2-daterangepicker';
@NgModule({
    imports:[
      CommonModule,
      ReactiveFormsModule,
      FormsModule,
      Daterangepicker
    ],
    declarations:[BacklistComponent],
    exports :[
       BacklistComponent
    ],
    providers:[
      BacklistService
    ]
})

export class BacklistModule {
}
