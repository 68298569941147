import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CouponDiscountComponent } from './coupon-discount.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerService } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  declarations: [CouponDiscountComponent],
  exports: [
    CouponDiscountComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class CouponDiscountModule { }
