import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { GateService, AuthService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-gate',
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.css']
})
export class GateComponent implements OnInit { 

gateList = new Array();
authenList = new Array();
gate = {"name":"","description":"","authen_id":""};
addForm: FormGroup;
editForm: FormGroup;
submitted_add = false;
submitted_edit = false;
currentDepartmentId;
fileName = 'Choose file';

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private gateService: GateService,) { 
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'authen_id': ['']
    });

    this.editForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'authen_id': [''],
      'id': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getAllGate();
    this.getAllAuth();
  }

  getAllAuth(){
    this.authenList = [];
    this.authService.getDevice().subscribe(data=>{
      this.authenList = data;
    });
  }
  
  getAllGate(){
    $("#gate_table").DataTable().clear().destroy();
    this.gateList = [];
    this.gateService.getGate().subscribe(data=>{
      this.gateList = data;
      console.log(data);
      setTimeout(() => {
        $('#gate_table').DataTable({
        });
      }, 10);
    });
  }

  openAddGate(){
    this.addForm.patchValue({
      name: "",
      description: "",
      file: "",
      file_manager_id: ""
    });
    $('#modal-add').modal('show');
  }

  addGate(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    console.log(value);
    this.gateService.addGate(value).subscribe(data => {
      this.submitted_add = false;
      $('#modal-add').modal('hide');
      this.getAllGate();
      this.successDialog();
    }, error => {
      $('#modal-add').modal('hide');
      this.failDialog(error);
    });
  }

  openEditGate(id){
    this.currentDepartmentId = id;
    this.gateService.getGateById(id).subscribe(data => {
      this.gate = data;
      console.log(data);
      this.editForm.patchValue({
        id: this.gate['id'],
        name: this.gate['name'],
        description: this.gate['description'],
        authen_id: this.gate['authen_id']
      });
      $('#modal-edit').modal('show');
    });
  }

  updateGate(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    console.log(value);
    this.gateService.updateGate(value.id, value).subscribe(data => {
      this.submitted_edit = false;
      $('#modal-edit').modal('hide');
      this.getAllGate();
      this.successDialog();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  openDeleteGate(id){
    this.currentDepartmentId = id;
    $('#modal-remove').modal('show');
  }

  deleteGate(){
    this.gateService.deleteGate(this.currentDepartmentId).subscribe(data => {
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.getAllGate();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
