import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { LogoService, UploadFilesService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit { 

logoList = new Array();
logo = {"name":"","description":"","file_manager_id":""};
addForm: FormGroup;
editForm: FormGroup;
submitted_add = false;
submitted_edit = false;
currentDepartmentId;
fileName = 'Choose file';

  constructor(private fb: FormBuilder,
    private logoService: LogoService,
    private uploadService: UploadFilesService,) { 
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'file': [''],
      'file_manager_id': ['', Validators.required]
    });

    this.editForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'file': [''],
      'file_manager_id': ['', Validators.required],
      'id': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getAllLogo();
  }

  getAllLogo(){
    $("#logo_table").DataTable().clear().destroy();
    this.logoList = [];
    this.logoService.getLogo().subscribe(data=>{
      this.logoList = data;
      setTimeout(() => {
        $('#logo_table').DataTable({
        });
      }, 10);
    });
  }

  openAddLogo(){
    this.fileManager = "";
    this.progressInfos = [];
    this.fileName = 'Choose file';
    this.addForm.patchValue({
      name: "",
      description: "",
      file: "",
      file_manager_id: ""
    });
    $('#modal-add').modal('show');
  }

  addLogo(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    console.log(value);
    this.logoService.addLogo(value).subscribe(data => {
      this.submitted_add = false;
      $('#modal-add').modal('hide');
      this.getAllLogo();
      this.successDialog();
    }, error => {
      $('#modal-add').modal('hide');
      this.failDialog(error);
    });
  }

  openEditLogo(id){
    this.currentDepartmentId = id;
    this.logoService.getLogoById(id).subscribe(data => {
      this.logo = data;
      this.fileManager = {url:this.logo['url']};
      this.fileName = this.logo['name_display'];
      this.editForm.patchValue({
        id: this.logo['id'],
        name: this.logo['name'],
        description: this.logo['description'],
        file_manager_id: this.logo['file_manager_id']
      });
      $('#modal-edit').modal('show');
    });
  }

  updateLogo(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    console.log(value);
    this.logoService.updateLogo(value.id, value).subscribe(data => {
      this.submitted_edit = false;
      $('#modal-edit').modal('hide');
      this.getAllLogo();
      this.successDialog();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  openDeleteLogo(id){
    this.currentDepartmentId = id;
    $('#modal-remove').modal('show');
  }

  deleteLogo(){
    this.logoService.deleteLogo(this.currentDepartmentId).subscribe(data => {
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.getAllLogo();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }


  fileManager;
  progressInfos = [];
  addSelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "logo");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.addForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }

  editelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "logo");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.editForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
