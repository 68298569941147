import { Component, OnInit } from '@angular/core';
import { CardVisitorService, CardTypeService, TemplateVisitorCardService, ReportService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { Constant } from '../../shared/constant/constant';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.css']
})
export class VisitorCardComponent implements OnInit {
  templateVisitorCardList = new Array();
  cardVisitorList = new Array();
  cardTypeList = new Array();
  addForm: FormGroup;
  editForm: FormGroup;
  errorCodeEnd;

  constructor(
    private fb: FormBuilder,
    private constant : Constant,
    private cardVisitorService: CardVisitorService,
    private templateVisitorCardService: TemplateVisitorCardService,
    private cardTypeService: CardTypeService,
    private reportService: ReportService
  ) { 
    this.addForm = fb.group({
      'start_code': ['', Validators.required],
      'end_code': [''],
      'per_fix': [''],
      'card_type_id': ['', Validators.required],
      'card_visitor_template_id': ['', Validators.required],
    });
    this.editForm = fb.group({
      'id': ['', Validators.required],
      'code': ['', Validators.required],
      'card_type_id': ['', Validators.required],
      'card_visitor_template_id': ['', Validators.required],
    });
   }

   ngOnInit() {  
     this.getAllCardVisitor();
     this.getAllCardType();
     this.getAllTemplateVisitorCard();
  }

  getAllTemplateVisitorCard(){
    this.templateVisitorCardList = [];
    this.templateVisitorCardService.get().subscribe(data=>{
      this.templateVisitorCardList = data;
    });
  }

  selectItem(item){
    item.checked = !item.checked;
  }

  isSelectAll = false;
  selectAll(){
    this.isSelectAll = !this.isSelectAll;
    this.cardVisitorList.forEach(element => {
      element['checked'] = this.isSelectAll;
    });
  }

  exportVisitorAllPdf_old(){
    let req = [];
    this.cardVisitorList.forEach(element => {
      req.push(element['id']);
    });
    console.log(req);
    this.reportService.reportVisitor(req).subscribe(data=>{
        console.log(data);
        window.open(data);
    });
  }

  exportVisitorPdf_old(){
    let req = [];
    this.cardVisitorList.forEach(element => {
      if(element['checked']){
        req.push(element['id']);
      }
    });
    console.log(req);
    this.reportService.reportVisitor(req).subscribe(data=>{
        console.log(data);
        window.open(data);
    });
  }

  deleteAll(){
    let req = [];
    this.cardVisitorList.forEach(element => {
      if(element['checked']){
        req.push(element['id']);
      }
    });
    this.cardVisitorService.deleteCardVisitorAll(req).subscribe(data => {
      $('#modal-remove-all').modal('hide');
      this.getAllCardVisitor();
      this.successDialog();
      this.isSelectAll = false;
    }, error => {
      $('#modal-remove-all').modal('hide');
      this.failDialog(error);
    });
  }

  getAllCardVisitor(){
    $("#card_visitor_table").DataTable().clear().destroy();
    this.cardVisitorList = [];
    this.cardVisitorService.getCardVisitor().subscribe(data=>{
      this.cardVisitorList = data;
      console.log(data);
      this.cardVisitorList.forEach(element => {
        element['checked'] = false;
        QRCode.toDataURL(element['code'], { errorCorrectionLevel: 'M' })
          .then(url => {
            element['qrCodeUrl'] = url;
          })
          .catch(err => {
            console.error(err);
          });
      });
      setTimeout(() => {
        $('#card_visitor_table').DataTable({
        });
      }, 10);
    });
  }
 
  getAllCardType(){
    this.cardTypeList = [];
    this.cardTypeService.getCardType().subscribe(data=>{
      this.cardTypeList = data;
    });
  }

  openAddVisitor(){
    this.addForm.patchValue({
      start_code: "",
      end_code: "",
      card_type_id: "",
      card_visitor_template_id: ""
    });
    $('#modal-add').modal('show');
  }

  submitted_add = false;
  createVisitorCard(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    console.log(value);
    this.cardVisitorService.addCardVisitor(value).subscribe(data => {
      this.submitted_add = false;
      $('#modal-add').modal('hide');
      this.getAllCardVisitor();
      this.successDialog();
    }, error => {
      $('#modal-add').modal('hide');
      this.failDialog(error);
    });
  }

  currentDepartmentId;
  openDeleteCardVisitor(id){
    this.currentDepartmentId = id;
    $('#modal-remove').modal('show');
  }

  deleteCardVisitor(){
    this.cardVisitorService.deleteCardVisitor(this.currentDepartmentId).subscribe(data => {
      $('#modal-remove').modal('hide');
      this.getAllCardVisitor();
      this.successDialog();
    }, error => {
      $('#modal-remove').modal('hide');
      this.failDialog(error);
    });
  }

  openEditCardVisitor(id){
    this.cardVisitorService.getCardVisitorById(id).subscribe(data => {
      this.editForm.patchValue({
        id: data['id'],
        code: data['code'],
        card_type_id: data['card_type_id'],
        card_visitor_template_id: data['card_visitor_template_id']
      });
      $('#modal-edit').modal('show');
    });
  }

  submitted_edit = false;
  updateVisitorCard(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    console.log(value);
    this.cardVisitorService.updateCardVisitor(value.id, value).subscribe(data => {
      this.submitted_edit = false;
      $('#modal-edit').modal('hide');
      this.getAllCardVisitor();
      this.successDialog();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  visitorCardIds
  isValid = false
  exportVisitorPdf(){
    this.visitorCardIds = []
    this.cardVisitorList.forEach(data => {
      // console.log(data['checked']);
      if(data['checked']){
        this.visitorCardIds.push(data.id);
        this.isValid = true;
      }
    });
    if(!this.isValid){
      this.warningDialog('กรุณาเลือกรายการ');
      return;
    }
    $('.loading').show();
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'ids': this.visitorCardIds
    }
    console.log(formData);
    this.cardVisitorService.createCardVisitorPdf(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {$('.loading').hide();}, 100);
      window.open(res.url, '_blank');
    }, error => {
      $('.loading').hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  exportVisitorAllPdf(){
    $('.loading').show();
    this.visitorCardIds = []
    this.cardVisitorList.forEach(data => {
      this.visitorCardIds.push(data.id);
    });
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'ids': this.visitorCardIds
    }
    this.cardVisitorService.createCardVisitorPdf(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {$('.loading').hide();}, 100);
      window.open(res.url, '_blank');
    }, error => {
      $('.loading').hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: '',
      text: msg
    })
  }

}
