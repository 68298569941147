import { Constant } from './../constant/constant';
import { retry ,catchError} from 'rxjs/operators';
import { Observable ,throwError} from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CompanyContractService {

  urlLink = '/companycontract';

  httpOptions = {
    headers :new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,private constant: Constant ) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;

  }

  // Get Company Contract List
   getCompContact(): Observable<any>{
     return this.http.get<any>(this.constant.API_ENDPOINT+this.urlLink,this.httpOptions);
   }

   addCompContact(companyContact): Observable<any>{
    console.log(JSON.stringify(companyContact));
    return this.http.post<any>(this.constant.API_ENDPOINT + this.urlLink, JSON.stringify(companyContact),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getCompContactById(companyid):Observable<any>{
       return this.http.get(this.constant.API_ENDPOINT+this.urlLink+"/id/"+companyid,this.httpOptions);
   }

   updateCompContact(id,companyContract):Observable<any>{
      return this.http.put<any>(this.constant.API_ENDPOINT + this.urlLink + '/'+id, JSON.stringify(companyContract),
      this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
   }

   deleteCompContact(companyid):Observable<any>{
    return this.http.patch<any>(this.constant.API_ENDPOINT + this.urlLink + '/' + companyid, {},
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   handleError(error) {
    console.log(error);
    let errorMessage = '';
    if(error.error && error.error.data) {
      errorMessage = error.error.data.error_message;
    }
    return throwError(errorMessage);
    }
}
