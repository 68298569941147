import { Component, OnInit } from '@angular/core';
import { CardVisitorService, CardTypeService, BackgroundPatternVisitorService, LogoService, UploadFilesService, TemplateVisitorCardService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-visitor-card_add',
  templateUrl: './template-visitor-card_add.component.html',
  styleUrls: ['./template-visitor-card_add.component.css']
})
export class TemplateVisitorCardAddComponent implements OnInit {
  logoList = new Array();
  backgroundPatternVisitorList = new Array();
  cardVisitorList = new Array();
  cardTypeList = new Array();
  addForm: FormGroup;
  editForm: FormGroup;
  errorCodeEnd;
  urlBackgroundPatternVisitor;
  urlLogo;
  fileName = 'Choose file';

  constructor(
    private fb: FormBuilder,
    private cardVisitorService: CardVisitorService,
    private cardTypeService: CardTypeService,
    private logoService: LogoService,
    private uploadService: UploadFilesService,
    private backgroundPatternVisitorService: BackgroundPatternVisitorService,
    private templateVisitorCardService: TemplateVisitorCardService,
    private _route:Router
  ) { 
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'topic': [''],
      'description': [''],
      'card_type': ['', Validators.required],
      'logo_id': [''],
      'background_pattern_visitor_id': [''],
      'file_manager_id': ['']
    });
   }

  ngOnInit() {
     this.getAllCardType();
     this.getAllLogo();
  }

  getAllLogo(){
    this.logoList = [];
    this.logoService.getLogo().subscribe(data=>{
      this.logoList = data;
    });
  }

  fileManager;
  progressInfos = [];
  addSelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "signature");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.addForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }

  changeBackgroundPatternVisitor(background_pattern_visitor_id){
    this.backgroundPatternVisitorList.forEach(element => {
      if(element['id'] == background_pattern_visitor_id){
        this.urlBackgroundPatternVisitor = element['url'];
      }
    });
  }

  changeLogo(logo_id){
    this.logoList.forEach(element => {
      if(element['id'] == logo_id){
        this.urlLogo = element['url'];
      }
    });
  }

  changeCardType(type){
    this.urlBackgroundPatternVisitor = "";
    this.addForm.patchValue({
      background_pattern_visitor_id: ""
    });
    if(type){
      console.log(type);
      this.backgroundPatternVisitorList = [];
      this.backgroundPatternVisitorService.getBackgroundPatternVisitorByType(type).subscribe(data=>{
        this.backgroundPatternVisitorList = data;
        console.log(data);
      });
    }
  }

  getAllCardType(){
    this.cardTypeList = [];
    this.cardTypeService.getCardType().subscribe(data=>{
      this.cardTypeList = data;
    });
  }

  submitted_add = false;
  submit(data){
    console.log(data);
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    console.log(data);
    this.templateVisitorCardService.add(data).subscribe(data => {
      this.submitted_add = false;
      this.successDialog();
    }, error => {
      this.failDialog(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
    setInterval(function(){ location.reload(); }, 1000);
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
