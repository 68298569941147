import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var jquery: any;
declare var $: any;
import { EmployeeService } from '../../shared';

@Component({
  selector: 'app-employee-view',
  templateUrl: './employee_view.component.html',
  styleUrls: ['./employee_view.component.css']
})
export class EmployeeViewComponent implements OnInit {
  employeeId;
  employee;

  constructor(
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    //get params
    this.activatedRoute.params.forEach((urlParams) => {
      this.employeeId = urlParams['id'].replace('#', '');
      console.log("employeeId : "+this.employeeId);
    });
    this.getEmployee();
  } 

  getEmployee(){
    this.employeeService.getEmployeeById(this.employeeId).subscribe(data => {
      this.employee = data;
      console.log(this.employee);
    });
  }

}
