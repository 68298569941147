import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class Constant {

API_ENDPOINT :string;
API_DOMAIN :string;

API_MANAGE_ENDPOINT :string;
API_MANAGE_DOMAIN :string;

API_REPORT_ENDPOINT :string;
API_REPORT_DOMAIN :string;

constructor() {
  this.API_ENDPOINT = environment.api_endpoint;
  this.API_DOMAIN = environment.api_domain;
  this.API_MANAGE_ENDPOINT = environment.api_manage_endpoint;
  this.API_MANAGE_DOMAIN = environment.api_manage_domain;

  this.API_REPORT_ENDPOINT = environment.api_report_endpoint;
  this.API_REPORT_DOMAIN = environment.api_report_domain;
  }
}