import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from './../constant/constant';
import {Observable,throwError} from 'rxjs';

var jsonRespone :any;
@Injectable({
  providedIn: 'root'
})
export class DashboardService{
  urlPath = "/visitor-info-transaction/";
  //status/count/all
  httpOptions = {
    headers:new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http:HttpClient , private constant:Constant)
  {
    let headers  = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
  }

  getCountAllStatus(): Observable<any>
  {
    console.log(this.constant.API_ENDPOINT+this.urlPath+"status/count/all");
    return this.http.get<any>(this.constant.API_ENDPOINT+this.urlPath+"status/count/all",this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError));

  }

  getCharts(type,period): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + this.urlPath+"type/"+type+"/period/"+period, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError));
  }



  getVisitStatus(data): Observable<any>
  {
    console.log(this.constant.API_ENDPOINT+this.urlPath+"status");

    return this.http.post<any>(this.constant.API_ENDPOINT+this.urlPath+"status", JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getVisitorRecord(data) : Observable<any>
  {
    return this.http.post<any>(this.constant.API_ENDPOINT+this.urlPath+"status/record", JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }




  // Error handling
  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if(error.error && error.error.data) {
      errorMessage = error.error.data.error_message;
    }
    return throwError(errorMessage);
    }
}


