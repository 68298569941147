import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VisitorTypeComponent } from './setting/visitorType/visitorType.component';
import { DepartmentComponent } from './setting/department/department.component';
import { CompanyContractComponent } from './setting/company-contract/company-contract.component';
import { BackgroundPatternVisitorComponent } from './setting/background-pattern-visitor/background-pattern-visitor.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RegisterCardComponent } from './card/register/register.component';
import { VisitorCardComponent } from './card/visitor/visitor.component';
import { CardTypeComponent } from './setting/card-type/card-type.component';
import { LogoComponent } from './setting/logo/logo.component';
import { GateComponent } from './setting/gate/gate.component';
import { TemplateVisitorCardAddComponent } from './setting/template-visitor-card/add/template-visitor-card_add.component';
import { TemplateVisitorCardEditComponent } from './setting/template-visitor-card/edit/template-visitor-card_edit.component';
import { TemplateVisitorCardComponent } from './setting/template-visitor-card/template-visitor-card.component';
import { ContactInformationVipComponent } from './contact-information-vip/contact-information-vip.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { ImportantPersonComponent } from './important-person/important-person.component';
import { BacklistComponent } from './backlist/backlist.component';
import { ImportDataCardRegisterComponent } from './setting/import-data-card-register/import-data-card-register.component';
import { ServiceFeeTypeComponent } from './setting/service-fee-type/service-fee-type.component';
import { CouponDiscountComponent } from './setting/coupon-discount/coupon-discount.component';
import { DashboardServicechargeComponent } from './dashboard-servicecharge/dashboard-servicecharge.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setting/visitor-type',
    component: VisitorTypeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setting/department',
    component: DepartmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setting/company-contract',
    component : CompanyContractComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/template-visitor-card',
    component : TemplateVisitorCardComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/template-visitor-card/add',
    component : TemplateVisitorCardAddComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/template-visitor-card/edit/:id',
    component : TemplateVisitorCardEditComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/background-pattern',
    component : BackgroundPatternVisitorComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'card/register',
    component : RegisterCardComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'card/visitor',
    component : VisitorCardComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/card-type',
    component : CardTypeComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/logo',
    component : LogoComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'setting/gate',
    component : GateComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'contact-information-vip',
    component : ContactInformationVipComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'contact-information',
    component : ContactInformationComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'important-person',
    component : ImportantPersonComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'back-list',
    component: BacklistComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'setting/import-data-card-register',
    component: ImportDataCardRegisterComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'setting/service-fee-type',
    component: ServiceFeeTypeComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'setting/coupon-discount',
    component: CouponDiscountComponent,
    canActivate:[AuthGuard]
  }
  ,
  {
    path:'dashboard-servicecharge',
    component: DashboardServicechargeComponent,
    canActivate:[AuthGuard]
  }

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
