import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  personnel;
  constructor(private http: HttpClient, private constant: Constant) { 
    
  }

  getDateTh(date){
    console.log(date);
    let dateTh = new Date(date);
    var dd = dateTh.getDate();
    var mm = dateTh.getMonth() + 1; //January is 0!
    var yyyy = dateTh.getFullYear();

    let month = "";
    let day = "";
    if (dd < 10) {
      day = "0" + dd;
    } else {
      day = "" + dd;
    }
    if (mm < 10) {
      month = "0" + mm;
    } else {
      month = "" + mm;
    }

    var startTime =
        (dateTh.getHours() < 10
        ? "0" + dateTh.getHours()
        : dateTh.getHours()) +
        "." +
        (dateTh.getMinutes() < 10
        ? "0" + dateTh.getMinutes()
        : dateTh.getMinutes());

    var convertDateTh = day + " " + this.convertMonthTH(month) + " " + (Number(yyyy) + 543) + " " + startTime + " น."
    return convertDateTh;
  }

  convertDateToStrng(date){
    console.log(date);
    let dateTh = new Date();
    if(date){
      dateTh = new Date(date);
    }
    
    var dd = dateTh.getDate();
    var mm = dateTh.getMonth() + 1; //January is 0!
    var yyyy = dateTh.getFullYear();

    let month = "";
    let day = "";
    if (dd < 10) {
      day = "0" + dd;
    } else {
      day = "" + dd;
    }
    if (mm < 10) {
      month = "0" + mm;
    } else {
      month = "" + mm;
    }

    var startTime =
        (dateTh.getHours() < 10
        ? "0" + dateTh.getHours()
        : dateTh.getHours()) +
        "." +
        (dateTh.getMinutes() < 10
        ? "0" + dateTh.getMinutes()
        : dateTh.getMinutes());

    var convertDateTh = yyyy + "-" + month + "-" +day;
    return convertDateTh;
  }

  convertDateToStrngDDMMYYYY(date){
    console.log(date);
    let dateCurrent = new Date();
    if(date){
      dateCurrent = new Date(date);
    }
    
    var dd = dateCurrent.getDate();
    var mm = dateCurrent.getMonth() + 1; //January is 0!
    var yyyy = dateCurrent.getFullYear();

    let month = "";
    let day = "";
    if (dd < 10) {
      day = "0" + dd;
    } else {
      day = "" + dd;
    }
    if (mm < 10) {
      month = "0" + mm;
    } else {
      month = "" + mm;
    }

    var startTime =
        (dateCurrent.getHours() < 10
        ? "0" + dateCurrent.getHours()
        : dateCurrent.getHours()) +
        "." +
        (dateCurrent.getMinutes() < 10
        ? "0" + dateCurrent.getMinutes()
        : dateCurrent.getMinutes());

    return day + "-" + month + "-" +yyyy;
  }

  convertMonthTH(m) {
    var month = "";
    if ("01" == m) {
      month = "มกราคม";
    } else if ("02" == m) {
      month = "กุมภาพันธ์";
    } else if ("03" == m) {
      month = "มีนาคม";
    } else if ("04" == m) {
      month = "เมษายน";
    } else if ("05" == m) {
      month = "พฤษภาคม";
    } else if ("06" == m) {
      month = "มิถุนายน";
    } else if ("07" == m) {
      month = "กรกฎาคม";
    } else if ("08" == m) {
      month = "สิงหาคม";
    } else if ("09" == m) {
      month = "กันยายน";
    } else if ("10" == m) {
      month = "ตุลาคม";
    } else if ("11" == m) {
      month = "พฤศจิกายน";
    } else if ("12" == m) {
      month = "ธันวาคม";
    }
    return month;
  }

  convertAddressToAddressDisplay(address){
    let addressDisplay = "";
    if(address != null){
      if(address.detail != null && address.detail != ""){
        addressDisplay = addressDisplay + address.detail + " ";
      }
      if(address.subdistrict != null && address.subdistrict != ""){
        addressDisplay = addressDisplay + "ตำบล" + address.subdistrict + " ";
      }
      if(address.district != null && address.district != ""){
        addressDisplay = addressDisplay + "อำเภอ" + address.district + " ";
      }
      if(address.province != null && address.province != ""){
        addressDisplay = addressDisplay + "จังหวัด" + address.province + " ";
      }
      if(address.postcode != null && address.postcode != ""){
        addressDisplay = addressDisplay + address.postcode;
      }
    }
    return addressDisplay;
  }

 
}