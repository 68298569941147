import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private constant: Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
  }

  reportVisitor(param): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/report/visitor', JSON.stringify(param),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
 
  downloadFile(url): any {
    return this.http.get(url, {responseType: 'blob'});
  }
 
  // Error handling
  handleError(error) {
  console.log(error);
  let errorMessage = '';
  if(error.error && error.error.data) {
    errorMessage = error.error.data.error_message;
  }
  return throwError(errorMessage);
  }
}