import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  HeaderComponent,
  MenuLeftComponent,
  LoginInitComponent,
  FooterComponent,
  Constant
} from './shared';
import { DashboardModule } from './dashboard/dashboard.module';
import { CompanyModule } from './setting/company/company.module';
import { DepartmentModule } from './setting/department/department.module';
import { PositionModule } from './setting/position/position.module';
import { MasterDataModule } from './setting/masterData/masterData.module';
import { EmployeeModule } from './employee/employee.module';
import { EmployeeViewModule } from './employee/view/employee_view.module';
import { EmployeeAddModule } from './employee/add/employee_add.module';
import { CustomerModule } from './customer/customer.module';
import { CustomerAddModule } from './customer/add/customer_add.module';
import { CustomerViewModule } from './customer/view/customer_view.module';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginModule } from './shared/layout/login.module';
import { VisitorTypeModule } from './setting/visitorType/visitorType.module';
import { CompanyContractModule } from './setting/company-contract/company-contract.module'; 
import { RegisterCardModule } from './card/register/register.module';
import { BackgroundPatternVisitorModule } from './setting/background-pattern-visitor/background-pattern-visitor.module';
import { VisitorCardModule } from './card/visitor/visitor.module';
import { CardTypeModule } from './setting/card-type/card-type.module';
import { LogoModule } from './setting/logo/logo.module';
import { TemplateVisitorCardAddModule } from './setting/template-visitor-card/add/template-visitor-card_add.module';
import { TemplateVisitorCardModule } from './setting/template-visitor-card/template-visitor-card.module';
import { TemplateVisitorCardEditModule } from './setting/template-visitor-card/edit/template-visitor-card_edit.module';
import { ContactInformationVipModule } from './contact-information-vip/contact-information-vip.module';
import { ContactInformationModule } from './contact-information/contact-information.module';
import { ImportantPersonModule } from './important-person/important-person.module';
import { BacklistModule} from './backlist/backlist.module';
import { GateModule } from './setting/gate/gate.module';
import { ImportDataCardRegisterModule } from './setting/import-data-card-register/import-data-card-register.module';
import { ServiceFeeTypeModule } from './setting/service-fee-type/service-fee-type.module';
import { CouponDiscountModule } from './setting/coupon-discount/coupon-discount.module';
import { DashboardServicechargeModule } from './dashboard-servicecharge/dashboard-servicecharge.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuLeftComponent,
    FooterComponent
    
    //LoginInitComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    CompanyModule,
    DepartmentModule,
    PositionModule,
    MasterDataModule,
    EmployeeModule,
    EmployeeViewModule,
    EmployeeAddModule,
    CustomerModule,
    CustomerAddModule,
    CustomerViewModule,
    LoginModule,
    VisitorTypeModule,
    CompanyContractModule,
    RegisterCardModule,
    BackgroundPatternVisitorModule,
    VisitorCardModule,
    CardTypeModule,
    LogoModule,
    TemplateVisitorCardModule,
    TemplateVisitorCardAddModule,
    TemplateVisitorCardEditModule,
    ContactInformationVipModule,
    ContactInformationModule,
    ImportantPersonModule,
    BacklistModule,
    GateModule,
    ServiceFeeTypeModule,
    CouponDiscountModule,
    ImportDataCardRegisterModule,
    DashboardServicechargeModule
  ],
  providers: [Constant],
  bootstrap: [AppComponent]
})
export class AppModule { }
