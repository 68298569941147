import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ContactInformationComponent } from './contact-information.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgxPaginationModule } from 'ngx-pagination';

import {
  PaginationModule
} from './../common-components/pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Daterangepicker,
    PaginationModule,
    NgxPaginationModule
  ],
  declarations: [ContactInformationComponent],
  exports: [
    ContactInformationComponent
  ],
  providers: [
    
  ]
})
export class ContactInformationModule { }
