import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyContractComponent } from './../../setting/company-contract/company-contract.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [CompanyContractComponent],
  exports: [
    CompanyContractComponent
  ],
  providers: [
  ]
})
export class CompanyContractModule { }
