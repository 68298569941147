import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { TemplateVisitorCardService, UploadFilesService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-import-data-card-register',
  templateUrl: './import-data-card-register.component.html',
  styleUrls: ['./import-data-card-register.component.css']
})
export class ImportDataCardRegisterComponent implements OnInit { 

templateVisitorCardList = new Array();
logoList = new Array();
logo = {"name":"","description":"","file_manager_id":""};
addForm: FormGroup;
editForm: FormGroup;
submitted_add = false;
submitted_edit = false;
currentDepartmentId;
errorMessage;
errorList = new Array();
sheet_name;
card_visitor_template_id = "";
is_invalid_card_visitor_template_id = false;


  constructor(private fb: FormBuilder,
    private templateVisitorCardService: TemplateVisitorCardService,
    private uploadService: UploadFilesService) { 
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'file': [''],
      'file_manager_id': ['', Validators.required]
    });

    this.editForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'file': [''],
      'file_manager_id': ['', Validators.required],
      'id': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getAllTemplateVisitorCard();
  }

  getAllTemplateVisitorCard(){
    this.templateVisitorCardList = [];
    this.templateVisitorCardService.get().subscribe(data=>{
      this.templateVisitorCardList = data;
    });
  }

  selectTemplateRegisterCard(){
    if(!this.card_visitor_template_id){
      this.is_invalid_card_visitor_template_id = true;
    }else{
      this.is_invalid_card_visitor_template_id = false;
    }
  }

  selectedFiles: FileList;
  selectFiles(event) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  fileName = 'Choose file';
  fileManager;
  progressInfos = [];
  addSelectFiles() {
    this.errorList = new Array();
    this.errorMessage = "";
    if(!this.card_visitor_template_id){
      this.is_invalid_card_visitor_template_id = true;
      return
    }
    let idx = 0;
    this.progressInfos = [];
    let file = this.selectedFiles[idx];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    console.log(this.card_visitor_template_id);
    console.log(this.sheet_name);
    var formData: FormData = new FormData();
    formData.append('file', file);
    $('.loading').show();
    $("#error_table").DataTable().clear().destroy();
    this.uploadService.uploadExcelFile(formData,this.card_visitor_template_id,this.sheet_name).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          setTimeout(() => {$('.loading').hide();}, 100);
          this.successDialog();
          this.errorList = event.body
          setTimeout(() => {
            $('#error_table').DataTable({
            });
          }, 10);
        }
      },
      err => {
        setTimeout(() => {$('.loading').hide();}, 100);
        this.failDialog('อัพโหลดไฟล์เกิดข้อผิดพลาด');
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.errorMessage = err.error.errors[0].message
        this.fileManager = "";
      });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
