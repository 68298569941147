import { Component, OnInit } from '@angular/core';
import { PositionService, EmployeeService } from 'src/app/shared';
import { CompanyService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  positionList = [];
  companyList = [];
  employeeList = [];
  addForm: FormGroup;
  submitted_add = false;
  editForm: FormGroup;
  submitted_edit = false;
  currentEmployeeId;
  employee;

  constructor(
    private positionService: PositionService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private employeeService: EmployeeService
    ) { 
      this.addForm = fb.group({
        'prefix': ['', Validators.required],
        'personnelCode': ['', Validators.required],
        'firstName': ['', Validators.required],
        'lastName': ['', Validators.required],
        'nickName': ['', Validators.required],
        'gender': ['M'],
        'tel': ['', Validators.required],
        'email': ['', Validators.required],
        'password': ['', Validators.required],
        'companyId': ['', Validators.required],
        'positionId': ['', Validators.required]
      });
  
      this.editForm = fb.group({
        'prefix': ['', Validators.required],
        'personnelCode': ['', Validators.required],
        'firstName': ['', Validators.required],
        'lastName': ['', Validators.required],
        'nickName': ['', Validators.required],
        'gender': ['M'],
        'tel': ['', Validators.required],
        'email': ['', Validators.required],
        'password': ['', Validators.required],
        'companyId': ['', Validators.required],
        'positionId': ['', Validators.required]
      });

    }

  ngOnInit() {
    this.getAllPosition();
    this.getAllCompany();
    this.searchAllEmployee();
  } 

  getAllPosition(){
    this.positionList = [];
    this.positionService.getPosition().subscribe(data => {
      this.positionList = data;
    });
  }

  getAllCompany(){
    this.companyList = [];
    this.companyService.getCompany().subscribe(data => {
      this.companyList = data;
    });
  }

  addEmployee(employee){
    console.log(employee);
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    
    this.employeeService.addEmployee(employee).subscribe(data => {
      this.submitted_add = false;
      $('#modal-employee-add').modal('hide');
      this.successDialog();
      this.searchAllEmployee();

    }, error => {
      let msg = error.split(" ")[2];
      let code = msg.split(/\n/)[0];
      this.failDialog(msg);
    });
    
  }

  searchAllEmployee(){
    this.employeeList = [];
    $("#employe_table").DataTable().clear().destroy();
    this.employeeService.getEmployee().subscribe(data => {
        this.employeeList = data;
        console.log(this.employeeList);

        setTimeout(() => {
          $('#employe_table').DataTable({
          });
        }, 100);

    })
  }

  deleteEmployee(id){
    this.currentEmployeeId = id;
    $('#modal-employee-remove').modal('show');
  }

  deleteProcessEmployee(id){
    this.employeeService.deleteEmployee(id).subscribe(data => {
      $('#modal-employee-remove').modal('hide');
      this.successDialog();
      this.searchAllEmployee();
    })
  }


  editEmployee(id){
    this.currentEmployeeId = id;
    this.employeeService.getEmployeeById(id).subscribe(data => {
      this.employee = data;
      console.log(data);

      this.editForm.patchValue({
        prefix: this.employee['prefix'],
        personnelCode: this.employee['personnelCode'],
        firstName: this.employee['firstName'],
        lastName: this.employee['lastName'],
        nickName: this.employee['nickName'],
        gender: this.employee['gender'],
        tel: this.employee['tel'],
        email: this.employee['email'],
        password: this.employee['password'],
        companyId: this.employee['position']['id'],
        positionId: this.employee['company']['id']
      });

      $('#modal-employee-edit').modal('show');
    });
  }

  updateEmployee(employee){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }

    console.log(employee);
    this.employeeService.updateEmployee(this.currentEmployeeId, employee).subscribe(data => {
      this.submitted_edit = false;
      $('#modal-employee-edit').modal('hide');
      this.searchAllEmployee();
      this.successDialog();
    })
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
