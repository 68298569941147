import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { EmployeeService, MasterDataService } from '../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-customer-add',
  templateUrl: './customer_add.component.html',
  styleUrls: ['./customer_add.component.css']
})
export class CustomerAddComponent implements OnInit {
  employeeId;
  employee;
  addForm: FormGroup;
  constructor(
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private masterDataService: MasterDataService,
    private httpService: HttpClient,
    private fb: FormBuilder
  ) { 
    this.addForm = fb.group({
      'gender': ['', Validators.required],
      'prefix': ['', Validators.required],
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'nick_name': [''],
      'identity_no': ['', Validators.required],
      'expired_card': ['', Validators.required],
      'tel': ['', Validators.required],
      'email': ['', Validators.required],
      'bank_account_no': [''],
      'date_of_birth': ['', Validators.required],
      'ethnicity': ['', Validators.required],
      'nationality': ['', Validators.required],
      'religion': ['', Validators.required],
      'weight': ['', Validators.required],
      'height': ['', Validators.required], 
      'military_condition': ['', Validators.required],
      'is_married': ['', Validators.required],
      'education_level': ['', Validators.required],
      'hospital1': ['', Validators.required],
      'hospital2': ['', Validators.required],
      'start_work': ['', Validators.required],
      'leave_work': ['', Validators.required],
      'company_id': ['', Validators.required],
      'department_id': ['', Validators.required],
      'position_id': ['', Validators.required],
      'address_detail': ['', Validators.required],
      'province': ['', Validators.required],
      'amphur': ['', Validators.required],
      'thumbon': ['', Validators.required],
      'postcode': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getProvince();
    this.getMasterDataActive();
  } 

  masterDataCategory;
  getMasterDataActive(){
    var param = {};
    param["category"] = "upload_type_employee";
    this.masterDataCategory = [];
    this.masterDataService.searchMasterData(param).subscribe(res=>{
        //console.log(res);
        this.masterDataCategory = res;
    });
  }

  submitted_add = false;
  saveEmployee(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
  }

  countryJson;
  getProvince() {
    this.countryJson = [];
    this.httpService.get("./assets/json/data.json").subscribe(
      data => {
        this.countryJson = data as string[]; // FILL THE ARRAY WITH DATA.
        // console.log(this.countryJson);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  amphurJson;
  getAmphur(e) {
    this.thumbonJson = [];
    if (e == "") {
      this.amphurJson = [];
    } else {
      this.postcode = '';
      let value = e.split("|");
      this.amphurJson = this.countryJson[value[0]][1];
    }
  }

  thumbonJson;
  getThumbon(e) {
    if (e == "") {
      this.postcode = '';
    } else {
      let value = e.split("|");
      this.thumbonJson = this.amphurJson[value[0]][1];
      let thumbons = this.thumbonJson[1];
      this.postcode = thumbons[1][0];
    }

    //console.log(thumbons[1][0])
  }

  postcode;
  getPost(e) {
    let value = e.split("|");
    let thumbons = this.thumbonJson[value[0]][1];
    this.postcode = thumbons[0];
    //console.log(this.postcode)
  }


}
