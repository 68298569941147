import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { GateComponent } from './gate.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [GateComponent],
  exports: [
    GateComponent
  ],
  providers: [
    
  ]
})
export class GateModule { }
