import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup ,Validators } from '@angular/forms';
import { CouponDiscountService } from '../../shared/service/coupon-discount.service';
import { CompanyContractService } from '../../shared/service/company-contract.service';
declare var jquery :any;
declare var $:any;
declare var Swal:any;

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-coupon-discount',
  templateUrl: './coupon-discount.component.html',
  styleUrls: ['./coupon-discount.component.css']
})
export class CouponDiscountComponent implements OnInit {

  addForm: FormGroup;
  submitted_add = false;
  editForm : FormGroup;
  submitted_edit =false;
  companyContactList =[];
  currentId;

  constructor(
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private couponDiscountService : CouponDiscountService,
      private compService : CompanyContractService
  ) {

    this.addForm = fb.group({
      'name': ['', Validators.required],
      'company_contract_id': ['', Validators.required],
      'conditions': ['', Validators.required],
      'period': [''],
      'time_unit': [''],
      'service_charge': [0],
    });
    this.editForm = fb.group({
      'name': ['', Validators.required],
      'company_contract_id': ['', Validators.required],
      'conditions': ['', Validators.required],
      'period': [''],
      'time_unit': [''],
      'service_charge': [0],
      'id': ['', Validators.required],
    });
  }


  ngOnInit() {
    this.get()
    this.getCompanyContact()
  }

  openModelAdd()
  {
    this.addForm.patchValue({
      name: '',
      company_contract_id: '',
      conditions: '',
      period: '',
      time_unit: '',
      service_charge: 0
    });
    $('#modal_add').modal('show')
  }

  getCompanyContact(){
    this.companyContactList = [];
    this.compService.getCompContact().subscribe(data=>{
      this.companyContactList = data;
    });
  }

  changeConditions(value,item){
    if(value == "2"){
      item.service_charge = 0
    }
  }

  serviceFeeTypeList
  get(){
    this.spinner.show();
    this.serviceFeeTypeList = [];
    $("#company_contact_table").DataTable().clear().destroy();
    this.couponDiscountService.getAll().subscribe(data=>{
      this.serviceFeeTypeList = data;
      console.log(this.serviceFeeTypeList);
      setTimeout(() => {
        $('#company_contact_table').DataTable({});
        this.spinner.hide();
      }, 10);
    },
    err => {
      this.spinner.hide();
      console.log(err);
      this.failDialog(err);
    });
  }

  addProcess(value){
    console.log(value);
    if(this.addForm.invalid){
      return;
    }
    this.spinner.show();
    this.couponDiscountService.add(value).subscribe(res=>{
        $('#modal_add').modal('hide');
        this.successDialog();
        this.get();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.failDialog(error);
    });
  }

  add(value){
    this.submitted_add = true;
    if(!value['period'] && value['conditions'] == 1){
      console.log('period')
      return;
    }
    if(!value['time_unit'] && value['conditions'] == 1){
      console.log('time_unit')
      return;
    }
    // if(!value['service_charge'] && value['conditions'] == 2){
    //   console.log('service_charge')
    //   return;
    // }
    this.addProcess(value)
  }

  updateProcess(value){
    if(this.editForm.invalid){
      return;
    }
    this.spinner.show();
    this.couponDiscountService.update(this.currentId, value).subscribe(res=>{
      $('#modal_edit').modal('hide');
        this.successDialog();
        this.get();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.failDialog(error);
    });
 }

   update(value){
    this.submitted_edit = true;
    if(!value['period'] && value['conditions'] == 1){
      console.log('period')
      return;
    }
    if(!value['time_unit'] && value['conditions'] == 1){
      console.log('time_unit')
      return;
    }
    // if(!value['service_charge'] && value['conditions'] == 2){
    //   console.log('service_charge')
    //   return;
    // }
    this.updateProcess(value)
   }

    openModelEdit(id)
    {
      this.currentId = id
      this.spinner.show();
      this.couponDiscountService.getById(id).subscribe(data=>{
        console.log(data);
        this.editForm.patchValue({
          id: data['id'],
          name: data['name'],
          conditions: data['conditions'],
          period: data['period'],
          time_unit: data['time_unit'],
          service_charge: data['service_charge'],
          company_contract_id: data['company_contract_id']
        });
        $('#modal_edit').modal('show');
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
        this.failDialog(error);
      });
    }

    openModelDelete(id)
    {
      this.currentId = id;
      console.log(this.currentId);
      $('#modal_delete').modal('show');
    }

    deleteComapnyContact(){
      this.spinner.show();
      this.couponDiscountService.delete(this.currentId).subscribe(res=>{
        $('#modal_delete').modal('hide');
        this.successDialog();
        this.get();
      }, error => {
        console.log(error);
        this.spinner.hide();
        this.failDialog(error);
      });
    }

    currentActive = 0
    openModelUpdateStatus(id,active)
    {
      this.currentActive = active;
      this.currentId = id;
      $('#modal_update_status').modal('show');
    }

    updateStatus(){
      this.spinner.show();
      var req = { "active": this.currentActive }
      this.couponDiscountService.updateStatus(this.currentId,req).subscribe(res=>{
        $('#modal_update_status').modal('hide');
        this.successDialog();
        this.get();
      }, error => {
        console.log(error);
        this.spinner.hide();
        this.failDialog(error);
      });
    }

   successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }


}
