import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { TemplateVisitorCardService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-template-visitor-card',
  templateUrl: './template-visitor-card.component.html',
  styleUrls: ['./template-visitor-card.component.css']
})
export class TemplateVisitorCardComponent implements OnInit { 

templateVisitorCardList = new Array();
currentDepartmentId;

  constructor(private fb: FormBuilder,
    private templateVisitorCardService: TemplateVisitorCardService) { 

  }

  ngOnInit() { 
    this.getAllTemplateVisitorCard();
  }

  getAllTemplateVisitorCard(){
    $("#background_pattern_visitor_table").DataTable().clear().destroy();
    this.templateVisitorCardList = [];
    this.templateVisitorCardService.get().subscribe(data=>{
      this.templateVisitorCardList = data;
      console.log(data);
      setTimeout(() => {
        $('#background_pattern_visitor_table').DataTable({
        });
      }, 10);
    });
  }

  openDelete(id){
    this.currentDepartmentId = id;
    $('#modal-remove').modal('show');
  }

  delete(){
    this.templateVisitorCardService.delete(this.currentDepartmentId).subscribe(data => {
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.getAllTemplateVisitorCard();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
