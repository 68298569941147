import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { ContactInformationService, CommonService, GateService } from 'src/app/shared';
import { HttpEventType, HttpResponse } from '@angular/common/http';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constant } from '../shared/constant/constant';
@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit { 
  contactInformationList = new Array();

  itemStart;
  itemEnd;

  page = 1;
  pageOutPut = 1;
  pageSizeOutPut = 10;
  from = 0;
  count;
  input;
  code;

  isShowImage = "false";

  gateIn = '';
  gateOut = '';

  constructor(private contactInformationService: ContactInformationService,
    private gateService: GateService,
    private constant : Constant,
    private commonService: CommonService) { }

  getRequestParams() {
    let params = {};

    if (this.gateIn) {
      params[`gateIn`] = this.gateIn;
    }

    if (this.gateOut) {
      params[`gateOut`] = this.gateOut;
    }

    if (this.cardType) {
      params[`cardType`] = this.cardType;
    }
    
    if (this.input) {
      params[`input`] = this.input;
    }

    if (this.code) {
      params[`code`] = this.code;
    }

    if (this.dateInFrom && this.isShowCalendarIn) {
      params[`dateInFrom`] = this.dateInFrom;
    }

    if (this.dateInTo && this.isShowCalendarIn) {
      params[`dateInTo`] = this.dateInTo;
    }

    if (this.dateOutFrom && this.isShowCalendarOut) {
      params[`dateOutFrom`] = this.dateOutFrom;
    }

    if (this.dateOutTo  && this.isShowCalendarOut) {
      params[`dateOutTo`] = this.dateOutTo;
    }

    if (this.pageOutPut) {
      params[`page`] = this.pageOutPut;
    }

    if (this.pageSizeOutPut) {
      params[`perPage`] = this.pageSizeOutPut;
    }

    params[`isShowImage`] = this.isShowImage;

    return params;
  }

  showCalendarIn(){
   this.isShowCalendarIn = !this.isShowCalendarIn;
  }

  showCalendarOut(){
    this.isShowCalendarOut = !this.isShowCalendarOut;
  }

  isShowCalendarIn = true;
  isShowCalendarOut = true;
  daterange: any = {};
  startDate;
  endDate;
  dateInFrom;
  dateInTo;
  dateOutFrom;
  dateOutTo;
  cardType = 'all';
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };
  selectedDateIn(value: any, datepicker?: any) {
    // this is the date  selected
    console.log(value);
 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.startDate = value.start._d;
    this.endDate = value.end._d;

    this.dateInFrom = this.commonService.convertDateToStrng(this.startDate);
    this.dateInTo = this.commonService.convertDateToStrng(this.endDate);
    
    // auto set time_out
    this.dateOut = this.commonService.convertDateToStrngDDMMYYYY(this.startDate)+" - "+this.commonService.convertDateToStrngDDMMYYYY(this.endDate);
    this.dateOutFrom = this.commonService.convertDateToStrng(this.startDate);
    this.dateOutTo = this.commonService.convertDateToStrng(this.endDate);
  }
  selectedDateOut(value: any, datepicker?: any) {
    // this is the date  selected
    console.log(value);
 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.startDate = value.start._d;
    this.endDate = value.end._d;

    this.dateOutFrom = this.commonService.convertDateToStrng(this.startDate);
    this.dateOutTo = this.commonService.convertDateToStrng(this.endDate);
  }
  
  dateIn;
  dateOut;
  ngOnInit() {
    this.dateIn = this.commonService.convertDateToStrngDDMMYYYY(new Date())+" - "+this.commonService.convertDateToStrngDDMMYYYY(new Date());
    this.dateOut = this.commonService.convertDateToStrngDDMMYYYY(new Date())+" - "+this.commonService.convertDateToStrngDDMMYYYY(new Date());
    this.dateInFrom = this.commonService.convertDateToStrng(new Date());
    this.dateInTo = this.commonService.convertDateToStrng(new Date());
    this.dateOutFrom = this.commonService.convertDateToStrng(new Date());
    this.dateOutTo = this.commonService.convertDateToStrng(new Date());
    this.search();
    this.getAllGate();
  }

  gateList;
  getAllGate(){
    this.gateList = [];
    this.gateService.getGate().subscribe(data=>{
      this.gateList = data;
      console.log(data);
    });
  }

  onClickSearch(){ 
    this.page = 1;
    this.pageOutPut = 1;
    // this.search();
    this.handlePageChange(1);
  }

  search(){ 
    $('.loading').show();
    this.contactInformationList = [];
    const params = this.getRequestParams();
    console.log(params);
    this.contactInformationService.search(params).subscribe(resp=>{
      console.log(resp);
      if(resp){
        this.contactInformationList = resp.data;
        this.count = resp.total;
        this.from = resp.from;
        this.itemStart = resp.from;
        this.itemEnd = resp.to;
      }
      setTimeout(() => {$('.loading').hide();}, 100);
    }, error => {
      console.log(error);
      $('.loading').hide();
    });
  }

  handlePageChange(pageOutPut){
    console.log("=== handlePageChange ===");
    this.pageOutPut = pageOutPut;
    this.search();
  }

  handlePageSizeChange(pageSizeOutPut){
    console.log("=== handlePageSizeChange ===");
    this.pageSizeOutPut = pageSizeOutPut;
    this.search();
  }

  openModalExportExcel(){
    $('#modal-select-image').modal('show');
  }

  exportExcel(){
    $('.loading').show();
    const params = this.getRequestParams();
    console.log(params);
    this.contactInformationService.exportExcel(params).subscribe(data=>{
      console.log(data);
      let blob = data;
      const fileName: string = 'visitor_info_transaction.xls';
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();        

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);

      setTimeout(() => {$('.loading').hide();$('#modal-select-image').modal('hide');}, 100);
    }, error => {
      console.log(error);
      $('.loading').hide();
    });
  }
  
  openInfo(id){
    // customer_id
    let customerId = localStorage.getItem('customer_id');
    window.open(this.constant.API_ENDPOINT + "/visitor-info-transaction/detail/pdf/" + id +"/customer/" + customerId, '_blank');
  }

}