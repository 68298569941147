import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VisitorTypeService } from '../../shared/service/visitorType.service';

@Component({
  selector: 'app-visitorType',
  templateUrl: './visitorType.component.html',
  styleUrls: ['./visitorType.component.css']
})
export class VisitorTypeComponent implements OnInit {

  addForm: FormGroup;
  submitted_add = false;
  editForm: FormGroup;
  submitted_edit = false;
  visitorTypeList = [];
  currentVisitorTypeId;
  visitorType = {"name":"","description":""};

  constructor(
    private fb: FormBuilder,
    private visitorTypeService: VisitorTypeService
  ) {
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
    });
    this.editForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'id': ['', Validators.required],
    });
   }

   ngOnInit() { 
    this.getAllVisitorType();
  }

   getAllVisitorType(){
    this.visitorTypeList = [];
    $("#visitor_type_table").DataTable().clear().destroy();

    this.visitorTypeService.getVisitorType().subscribe(data=>{
      this.visitorTypeList = data;
      console.log(this.visitorTypeList);
      setTimeout(() => {
        $('#visitor_type_table').DataTable({
        });
      }, 10);
    });
   }

   saveVisitorType(value){
    console.log(value);
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    this.visitorTypeService.addVisitorType(value).subscribe(res=>{
        $('#visitor_type_modal_add').modal('hide');
        this.successDialog();
        this.getAllVisitorType();
    }, error => {
      console.log(error);
      //let msg = error.split(" ")[2];
      //let code = msg.split(/\n/)[0];
      $('#visitor_type_modal_add').modal('hide');
      this.failDialog(error);
    });
   }

   openModalEdit(id){
    this.currentVisitorTypeId = id;
    console.log(this.currentVisitorTypeId);
    this.visitorTypeService.getVisitorTypeById(this.currentVisitorTypeId).subscribe(data=>{
      this.visitorType = data;
      this.editForm.patchValue({
        id: this.visitorType['id'],
        name: this.visitorType['name'],
        description: this.visitorType['description']
      });
      $('#visitor_type_modal_edit').modal('show');
    });
   }

   updateVisitorType(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    this.visitorTypeService.updateVisitorType(this.currentVisitorTypeId, value).subscribe(res=>{
      $('#visitor_type_modal_edit').modal('hide');
        this.successDialog();
        this.getAllVisitorType();
    }, error => {
      console.log(error);
      //let msg = error.split(" ")[2];
      //let code = msg.split(/\n/)[0];
      $('#visitor_type_modal_edit').modal('hide');
      this.failDialog(error);
    });
   }

   openModalDelete(id){
    this.currentVisitorTypeId = id;
    console.log(this.currentVisitorTypeId);
    $('#visitor_type_modal_delete').modal('show');
  }
 
  deleteVisitorType(){
    this.visitorTypeService.deleteVisitorType(this.currentVisitorTypeId).subscribe(res=>{
      $('#visitor_type_modal_delete').modal('hide');
      this.successDialog();
      this.getAllVisitorType();
    }, error => {
      console.log(error);
      //let msg = error.split(" ")[2];
      //let code = msg.split(/\n/)[0];
      $('#visitor_type_modal_delete').modal('hide');
      this.failDialog(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

  loadDefualtScript(){

    setTimeout(() => {
      $('#visitor_type_table').DataTable({
      });
    }, 100);

  }

}
