import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { retry, catchError } from 'rxjs/operators';
import { Constant } from './../constant/constant';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DashboardServicechargeService {
  urlPath = "/visitor-info-transaction/";

  httpOptions = {
    headers:new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(private http: HttpClient, private constant: Constant) {
    let headers  = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
  }

  getCard(): Observable<any>
  {
    // console.log(this.constant.API_ENDPOINT+this.urlPath+"service-charges/card");
    return this.http.get<any>(this.constant.API_ENDPOINT+this.urlPath+"service-charges/card",this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError));

  }

  getCharts(month): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + this.urlPath+"service-charges/chart/month/"+month, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if(error.error && error.error.data) {
      errorMessage = error.error.data.error_message;
    }
    return throwError(errorMessage);
    }
}
