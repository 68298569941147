import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { CardVisitorService, CardTypeService, 
  TemplateVisitorCardService, ReportService,
  CompanyContractService, DepartmentService,
  UploadFilesService,CardRegisterService,
  CommonService  } from 'src/app/shared';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Constant } from '../../shared/constant/constant';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterCardComponent implements OnInit {
  
  cardRegisterList = new Array();
  templateVisitorCardList = new Array();
  cardTypeList = new Array();
  companyContractList = new Array();
  departmentList = new Array();
  addForm: FormGroup;
  submitted_add = false;
  editForm: FormGroup;
  submitted_edit = false;
  fileManager;
  progressInfos = [];
  fileName = 'Choose file';
  currentCardRegisterID;
  cardRegister;

  //issue date
  issueDate;
  //expired date
  expiredDate;
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  };

  constructor(
    private fb: FormBuilder,
    private constant : Constant,
    private cardVisitorService: CardVisitorService,
    private templateVisitorCardService: TemplateVisitorCardService,
    private cardTypeService: CardTypeService,
    private reportService: ReportService,
    private companyContractService: CompanyContractService,
    private departmentService: DepartmentService,
    private uploadService: UploadFilesService,
    private cardRegisterService: CardRegisterService,
    private commonService: CommonService,
  ) { 
    this.addForm = fb.group({
      'code': ['', Validators.required],
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'gender': ['', Validators.required],
      'car_no': ['', Validators.required],
      'tel_no': ['', Validators.required],
      'issue_date': [''],
      'expired_date': [''],
      'card_type_id': ['', Validators.required],
      'card_visitor_template_id': ['', Validators.required],
      'file_manager_id': [''],
      'company_contract_id': ['', Validators.required],
      'department_id': ['', Validators.required]
    });
    this.editForm = fb.group({
      'id': ['', Validators.required],
      'code': ['', Validators.required],
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'gender': ['', Validators.required],
      'car_no': ['', Validators.required],
      'tel_no': ['', Validators.required],
      'issue_date': [''],
      'expired_date': [''],
      'card_type_id': ['', Validators.required],
      'card_visitor_template_id': ['', Validators.required],
      'file_manager_id': [''],
      'file': [''],
      'company_contract_id': ['', Validators.required],
      'department_id': ['', Validators.required]
    });
   }

   ngOnInit() {  
     //issue date
     this.issueDate = this.commonService.convertDateToStrng(new Date())
    //  this.issueDate.setHours(0);
    //  this.issueDate.setMinutes(0);
    //  this.issueDate.setSeconds(0);
     //expiered date
     this.expiredDate = this.commonService.convertDateToStrng(new Date())
    //  this.expiredDate.setHours(0);
    //  this.expiredDate.setMinutes(0);
    //  this.expiredDate.setSeconds(0);

     //get all card register
     this.getAllRegisterCard();
     this.getAllCardType();
     this.getAllTemplateVisitorCard();
     this.getAllCompanyContract();
     this.getAllDepartment();
  }

  getAllCardType(){
    this.cardTypeList = [];
    this.cardTypeService.getCardType().subscribe(data=>{
      this.cardTypeList = data;
    });
  }

  getAllTemplateVisitorCard(){
    this.templateVisitorCardList = [];
    this.templateVisitorCardService.get().subscribe(data=>{
      this.templateVisitorCardList = data;
    });
  }

  getAllCompanyContract(){
    this.companyContractList = [];
    this.companyContractService.getCompContact().subscribe(data=>{
      this.companyContractList = data;
      //console.log(this.companyContractList);
    });
  }

  getAllDepartment(){
    this.departmentList = [];
    this.departmentService.getDepartment().subscribe(data =>{
      this.departmentList = data;
      //console.log(this.departmentList);
    });
  }

  getAllRegisterCard(){
    $('.loading').show();
    $("#card_register_table").DataTable().clear().destroy();
    this.cardRegisterList = [];
    this.cardRegisterService.getCardRegister().subscribe(data=>{
      this.cardRegisterList = data;
      console.log(this.cardRegisterList);

      this.cardRegisterList.forEach(element => {
        QRCode.toDataURL(element['code'], { errorCorrectionLevel: 'M' })
        .then(url => {
          element['qrCodeUrl'] = url;
        })
        .catch(err => {
          console.error(err);
        });
      });

      setTimeout(() => {
        $('#card_register_table').DataTable({
          "aLengthMenu": [[10, 50, 100, 500, -1], [10, 50, 100, 500, "ทั้งหมด"]],
          "columnDefs": [
            { "orderable": false, "targets": 0 }
          ]
        });
        $('.loading').hide();
      }, 10);
    }, error => {
      $('.loading').hide();
      this.failDialog(error);
    });
  }

  createRegisterCard(value){
    this.submitted_add = true;
    //patch type date
    /*this.addForm.patchValue({
      issue_date: this.issueDate,
      expired_date: this.expiredDate
    });*/
    if(this.addForm.invalid){
      return;
    }
    let formData = {
      'code':value['code'],
      'first_name':value['first_name'],
      'last_name':value['last_name'],
      'gender':value['gender'],
      'tel_no':value['tel_no'],
      'car_no':value['car_no'],
      'issue_date': this.issueDate,
      'expired_date': this.expiredDate,
      'card_type_id':value['card_type_id'],
      'card_visitor_template_id':value['card_visitor_template_id'],
      'file_manager_id':value['file_manager_id'],
      'company_contract_id':value['company_contract_id'],
      'department_id':value['department_id']
    }
    console.log(formData);

    this.cardRegisterService.addCardRegister(formData).subscribe(res=>{
      this.successDialog();
      $('#modal-add-register-card').modal('hide');
      this.getAllRegisterCard();
    }, error => {
        $('#modal-add-register-card').modal('hide');
        this.failDialog(error);
      });
  }


  addSelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "register_card");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.addForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }


  openModalEdit(id){
    this.submitted_edit = false;
    this.progressInfos = [];
    this.currentCardRegisterID = id;
    this.cardRegisterService.getCardRegisterById(id).subscribe(data => {
      this.cardRegister = data;
      this.fileName = 'Choose file';
        this.fileManager = {url:this.cardRegister['profile_photo']};
        if(this.cardRegister['name_display']){
          this.fileName = this.cardRegister['name_display'];
        }

      this.editForm.patchValue({
        id: this.cardRegister['id'],
        code: this.cardRegister['code'],
        first_name: this.cardRegister['first_name'],
        last_name: this.cardRegister['last_name'],
        gender: this.cardRegister['gender'],
        car_no: this.cardRegister['car_no'],
        tel_no: this.cardRegister['tel_no'],
        issue_date: this.commonService.convertDateToStrngDDMMYYYY(this.cardRegister['issue_date']),
        expired_date: this.commonService.convertDateToStrngDDMMYYYY(this.cardRegister['expired_date']),
        card_type_id: this.cardRegister['card_type_id'],
        card_visitor_template_id: this.cardRegister['card_visitor_template_id'],
        file_manager_id: this.cardRegister['file_manager_id'],
        company_contract_id: this.cardRegister['company_contract_id'],
        department_id: this.cardRegister['department_id'],
        file: ""
      });
      this.issueDate = this.cardRegister['issue_date'];
      this.expiredDate = this.cardRegister['expired_date'];
      $('#modal-edit-register-card').modal('show');
    });
  }

  editelectFiles(event) {
    let idx = 0;
    this.progressInfos = [];
    let file = event.target.files[0];
    console.log(file);
    this.fileName = file.name;
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', "register_card");

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            this.fileManager = event.body.data;
            console.log(this.fileManager);
            this.editForm.patchValue({
              file_manager_id: this.fileManager.id
            });
          }
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        console.log(err);
        this.fileManager = "";
      });
  }

  updateCardRegister(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    } 
    let formData = {
      'code':value['code'],
      'first_name':value['first_name'],
      'last_name':value['last_name'],
      'gender':value['gender'],
      'car_no':value['car_no'],
      'tel_no':value['tel_no'],
      'issue_date': this.issueDate,
      'expired_date': this.expiredDate,
      'card_type_id':value['card_type_id'],
      'card_visitor_template_id':value['card_visitor_template_id'],
      'file_manager_id':value['file_manager_id'],
      'company_contract_id':value['company_contract_id'],
      'department_id':value['department_id']
    }
    console.log(formData);

    this.cardRegisterService.updateCardRegister(this.currentCardRegisterID, formData).subscribe(res=>{
      this.successDialog();
      $('#modal-edit-register-card').modal('hide');
      this.getAllRegisterCard();
    }, error => {
        $('#modal-edit-register-card').modal('hide');
        this.failDialog(error);
      }); 
  }

  openModalDelete(id){
    this.currentCardRegisterID = id;
    $('#modal-remove-register-card').modal('show');
  }

  deleteCardRegister(){
    this.cardRegisterService.deleteCardRegister(this.currentCardRegisterID).subscribe(res=>{
      $('#modal-remove-register-card').modal('hide');
      this.successDialog();
      this.getAllRegisterCard();
    }, error => {
        $('#modal-remove-register-card').modal('hide');
        this.failDialog(error);
      });
  }

  isSelectAll = false;
  selectAll(){
    var table = $('#card_register_table').DataTable();
    var info = table.page.info();
    console.log(info)
    this.isSelectAll = !this.isSelectAll;
    var i = 0;
    var start = info.start
    var end = info.end
    this.cardRegisterList.forEach(element => {
      element['checked'] = false
    });
    this.cardRegisterList.forEach(element => { // 0 -> 10
      // console.log(start + " <= " + i + " && " + i + "<" + end)
      if(start <= i && i < end){
        element['checked'] = this.isSelectAll;
      }
      i++
    });
  }

  deleteAll(){
    let req = [];
    this.cardRegisterList.forEach(element => {
      if(element['checked']){
        req.push(element['id']);
      }
    });
    this.cardRegisterService.deleteCardRegisterAll(req).subscribe(data => {
      $('#modal-remove-all-register-card').modal('hide');
      this.getAllRegisterCard();
      this.successDialog();
      this.isSelectAll = false;
    }, error => {
      $('#modal-remove-all-register-card').modal('hide');
      this.failDialog(error);
    });
  }

  selectedIssueDate(value: any, datepicker?: any) {
    // this.issueDate = value.start._d;
    this.issueDate = this.commonService.convertDateToStrng(value.start._d)
    console.log(this.issueDate);
  }

  selectedExpiredDate(value: any, datepicker?: any) {
    // this.expiredDate = value.start._d;
    this.expiredDate = this.commonService.convertDateToStrng(value.start._d)
    console.log(this.expiredDate);
  }

  exportRegisterAllPdf_old(){
    $('.loading').show();
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'registerCardIds': []
    }

    this.cardRegisterService.createCardRegisterPdf_old(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {$('.loading').hide();}, 100);
      window.open(this.constant.API_ENDPOINT + "/card-register/download-pdf/customer/"+customerId, '_blank');
    }, error => {
      $('.loading').hide();
      this.failDialog(error);
    });
  }

  selectItem(item){
    item.checked = !item.checked;
  }

  isValid = false
  exportRegisterPdf_old(){
    this.registerCardIds = []
    this.cardRegisterList.forEach(data => {
      // console.log(data['checked']);
      if(data['checked']){
        this.registerCardIds.push(data.id);
        this.isValid = true;
      }
    });
    if(!this.isValid){
      this.warningDialog('กรุณาเลือกรายการ');
      return;
    }
    $('.loading').show();
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'registerCardIds': this.registerCardIds
    }
    console.log(formData);
    this.cardRegisterService.createCardRegisterPdf_old(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {$('.loading').hide();}, 100);
      window.open(this.constant.API_ENDPOINT + "/card-register/download-pdf/customer/"+customerId, '_blank');
    }, error => {
      $('.loading').hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  registerCardIds
  exportRegisterPdf(){
    this.registerCardIds = []
    this.cardRegisterList.forEach(data => {
      // console.log(data['checked']);
      if(data['checked']){
        this.registerCardIds.push(data.id);
        this.isValid = true;
      }
    });
    if(!this.isValid){
      this.warningDialog('กรุณาเลือกรายการ');
      return;
    }
    $('.loading').show();
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'ids': this.registerCardIds
    }
    console.log(formData);
    this.cardVisitorService.createCardRegisterReport(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {$('.loading').hide();}, 100);
      window.open(res.url, '_blank');
    }, error => {
      $('.loading').hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  exportRegisterAllPdf(){
    $('.loading').show();
    this.registerCardIds = []
    this.cardRegisterList.forEach(data => {
      this.registerCardIds.push(data.id);
    });
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'ids': this.registerCardIds
    }
    this.cardVisitorService.createCardRegisterReport(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {$('.loading').hide();}, 100);
      window.open(res.url, '_blank');
    }, error => {
      $('.loading').hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  openAddRegister(){
    $('#modal-add-register-card').modal('show');
  }
 
  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: '',
      text: msg
    })
  }

}
