import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class GateService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private constant: Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
  }

  getGate(): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/gate', this.httpOptions);
  }

  getGateById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/gate/' + id, this.httpOptions);
  }

  updateGate(id, data): Observable<any> {
    return this.http.put<any>(this.constant.API_ENDPOINT + '/gate/' + id, JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addGate(data): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/gate', JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteGate(id): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + '/gate/' + id, {},
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
  console.log(error);
  let errorMessage = '';
  if(error.error && error.error.data) {
    errorMessage = error.error.data.error_message;
  }
  return throwError(errorMessage);
  }
}