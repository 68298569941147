import { Constant } from './../constant/constant';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

var jsonRespone :any;
@Injectable({
  providedIn: 'root'
})
export class BacklistService {
  urlLink = '/backlist';
  httpOptions = {
    headers:new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http:HttpClient , private constant : Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
   }


   // Get backlist list
  getBacklist(): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + this.urlLink, this.httpOptions);
  }

  // Get backlist by id
  getBacklistById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + this.urlLink+"/id/" + id, this.httpOptions);
  }

  // Update backlist by id
  updateBacklist(id, backlist): Observable<any> {
    return this.http.put<any>(this.constant.API_ENDPOINT + this.urlLink+"/" + id, JSON.stringify(backlist),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Add backlist
  addBacklist(backlist): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + this.urlLink, JSON.stringify(backlist),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Delete backlist by id
  deleteBacklist(id): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + this.urlLink+"/" + id, {},
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
  console.log(error);
  let errorMessage = '';
  if(error.error && error.error.data) {
    errorMessage = error.error.data.error_message;
  }
  return throwError(errorMessage);
  }
}
