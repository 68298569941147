import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmployeeViewComponent } from './employee_view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [EmployeeViewComponent],
  exports: [
    EmployeeViewComponent
  ],
  providers: [
  ]
})
export class EmployeeViewModule { }
