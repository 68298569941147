import { DashboardService } from './../shared/service/dashboard.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup,ReactiveFormsModule  } from '@angular/forms';
import * as Chart from 'chart.js';
import { ElementRef } from '@angular/core';
import { DashboardServicechargeService } from '../shared/service/dashboard-servicecharge.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-dashboard-servicecharge',
  templateUrl: './dashboard-servicecharge.component.html',
  styleUrls: ['./dashboard-servicecharge.component.css']
})
export class DashboardServicechargeComponent implements OnInit, AfterViewInit {

  public cboMonthIn: any;
  public totalDay: number;
  public visitDay: number;
  public totalMonth: number;
  public visitMonth: number;

  private label: Array<any> = [];
  private dataChart: Array<any> = [];

  private chartOptions: any;
  chart: any;
  public month: any;

  public arrMonth: Array<any> = [];
  public fromSelect: FormGroup;

  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  public ctx: CanvasRenderingContext2D;
  constructor(private service: DashboardServicechargeService ,private fb: FormBuilder) {

    this.month = (new Date().getMonth() + 1 + '').padStart(2, '0');

    this.arrMonth =
      [
        { id: "01", code: "มกราคม" },
        { id: "02", code: "กุมภาพันธ์" },
        { id: "03", code: "มีนาคม" },
        { id: "04", code: "เมษายน" },
        { id: "05", code: "พฤษภาคม" },
        { id: "06", code: "มิถุนายน" },
        { id: "07", code: "กรกฏาคม" },
        { id: "08", code: "สิงหาคม" },
        { id: "09", code: "กันยายน" },
        { id: "10", code: "ตุลาคม" },
        { id: "11", code: "พฤศจิกายน" },
        { id: "12", code: "ธันวาคม" }
      ];

      
      this.fromSelect = fb.group({
        'cboMonthIn': [this.month]
      });

  }
  ngAfterViewInit(): void {
    this.chartOptions = {
      responsive: true,
      legend: {
        display: true
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            color: "#f3f3f3",
            drawTicks: false,
          },
          scaleLabel: {
            display: true,
          },
          ticks: {
            padding: 10
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            color: "#f3f3f3",
            drawTicks: false,
          },
          scaleLabel: {
            display: true,
          },
          ticks: {
            padding: 10
          }
        }]
      },
      title: {
        display: false,
        text: 'title'
      },
      animation: {
        onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            Chart.helpers.each(meta.data.forEach(function (bar, index) {
              ctx.fillText(dataset.data[index], bar._model.x, bar._model.y - 10);
            }), this);
          }), this);
        }
      }
    };

    this.ctx = this.canvas.nativeElement.getContext('2d');

    this.bindCharts(false);

    this.getCharts();    
  }

  ngOnInit() {
    $('.loading').show();
    this.totalDay = 0;
    this.totalMonth = 0;
    this.visitDay = 0;
    this.visitMonth = 0;    
    this.getCard();

  }
  bindCharts(isUpdate) {
    if (!isUpdate) {
      // Chart Data
      var chartData = {
        labels: this.label,
        datasets: [{
          type: 'bar',
          label: "ยอดค่าบริการแต่ละวัน",
          data: this.dataChart,
          backgroundColor: "#36A2EB",
          borderColor: "transparent",
          borderWidth: 2
        }]
      };

      var config = {
        type: 'bar',
        // Chart Options
        options: this.chartOptions,

        data: chartData
      };
      this.chart = new Chart(this.ctx, config);
    }
    else {
      this.removeChartData(this.chart);
      this.updateChartData(this.chart, this.label, this.dataChart);
    }

  }

  updateChartData(chart, label, data) {
    chart.data.labels = label;
    chart.data.datasets.forEach((dataset) => {
      dataset.data = data;
    });
    chart.update();
    setTimeout(() => {
      $('.loading').hide();
    },1000);
  }

  removeChartData(chart) {
    chart.data.labels = [];
    chart.data.datasets.forEach((dataset) => {
      dataset.data = [];
    });
    chart.update();
  }

  getCard() {
    try {
      this.service.getCard().subscribe(data => {
        // console.log("===========  Card ServiceCharge ========");
        // console.log(data);


        data.forEach(obj => {

          if (obj.preriod === 'MONTH') {
            this.totalMonth = obj.total;
            this.visitMonth = obj.amount;
          }

          if (obj.preriod === 'DAY') {
            this.totalDay = obj.total;
            this.visitDay = obj.amount;
          }
        });
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  getCharts() {
    this.service.getCharts(this.month).subscribe(data => {
      this.dataChart = [];
      this.label = [];
      data.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
          if (key == '_day')
            this.label.push("วันที่ " + parseInt(value + ''));
          if (key == 'total')
            this.dataChart.push(value);
        });
      });
      this.updateChartData(this.chart, this.label, this.dataChart);
    });
  }

  reChart() {
    $('.loading').hide();
    this.getCharts();
  }

  selectMonth(month: string) {
    console.log(month);
    this.month = month;
    this.getCharts();
  }


}
