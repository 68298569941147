import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ImportantPersonComponent } from './important-person.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [ImportantPersonComponent],
  exports: [
    ImportantPersonComponent
  ],
  providers: [
    
  ]
})
export class ImportantPersonModule { }
