import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TemplateVisitorCardComponent } from './template-visitor-card.component';
import {
  
} from '../../shared';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [TemplateVisitorCardComponent],
  exports: [
    TemplateVisitorCardComponent
  ],
  providers: [
    
  ]
})
export class TemplateVisitorCardModule { }
