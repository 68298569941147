import { Constant } from '../constant/constant';
import { retry ,catchError} from 'rxjs/operators';
import { Observable ,throwError} from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ServiceFeeTypeService {

  urlLink = '/service-fee-type';

  httpOptions = {
    headers :new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,private constant: Constant ) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;

  }

   getAll(): Observable<any>{
     return this.http.get<any>(this.constant.API_ENDPOINT+this.urlLink,this.httpOptions);
   }

   add(req): Observable<any>{
    return this.http.post<any>(this.constant.API_ENDPOINT + this.urlLink, JSON.stringify(req),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getById(id):Observable<any>{
       return this.http.get(this.constant.API_ENDPOINT+this.urlLink+"/"+id,this.httpOptions);
   }

   update(id,req):Observable<any>{
      return this.http.put<any>(this.constant.API_ENDPOINT + this.urlLink + '/'+id, JSON.stringify(req),
      this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
   }

   delete(id):Observable<any>{
    return this.http.delete<any>(this.constant.API_ENDPOINT + this.urlLink + '/' + id,
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   handleError(error) {
    console.log(error);
    let errorMessage = '';
    if(error.error && error.error.data) {
      errorMessage = error.error.data.error_message;
    }
    return throwError(errorMessage);
    }
}
