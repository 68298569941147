import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {

  constructor(
    private authenService: AuthService
  ) {
   }

   private imagesName:string;
   private customerName:string;

  ngOnInit(): void {
    this.imagesName = localStorage.getItem("customer_id");
    this.customerName = localStorage.getItem("customer_name");
  }

  unAuthen(){
    this.authenService.sendLogout().subscribe(res=>{
      this.authenService.clearStorage();
    });
  }
}
