import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { ImportantPersonService, UploadFilesService, CommonService } from 'src/app/shared';
import { HttpEventType, HttpResponse } from '@angular/common/http';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-important-person.component',
  templateUrl: './important-person.component.html',
  styleUrls: ['./important-person.component.css']
})
export class ImportantPersonComponent implements OnInit { 

  importantPersonList = new Array();
  importantPerson = {"name":"","description":""};
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  currentId;
  accessToken;
  fileName = 'Choose file';
  
    constructor(private fb: FormBuilder,
      private uploadService: UploadFilesService,
      private commonService: CommonService,
      private importantPersonService: ImportantPersonService) { 
      this.addForm = fb.group({
        'position': ['', Validators.required],
        'car_registration': ['', Validators.required],
        'file': [''],
        'file_manager_id': ['', Validators.required],
      });
  
      this.editForm = fb.group({
        'position': ['', Validators.required],
        'car_registration': ['', Validators.required],
        'file': [''],
        'file_manager_id': ['', Validators.required],
        'id': ['', Validators.required]
      });
  
      this.accessToken = 'Bearer ' + localStorage.getItem('access_token');
    }
  
    ngOnInit() { 
      this.getAllImportantPerson();
    }
  
    openAddImportantPerson(){
      this.fileManager = "";
      this.progressInfos = [];
      this.fileName = 'Choose file';
      this.addForm.patchValue({
        position: "",
        car_registration: "",
        file: "",
        file_manager_id: ""
      });
      $('#modal-add').modal('show');
    }

    fileManager;
    progressInfos = [];
    addSelectFiles(event) {
      let idx = 0;
      this.progressInfos = [];
      let file = event.target.files[0];
      console.log(file);
      this.fileName = file.name;
      this.progressInfos[idx] = { value: 0, fileName: file.name };
      var formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('type', "important_person");
  
      this.uploadService.upload(formData).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            console.log(event);
            if(event.body.code === "success" && event.body.data){
              this.fileManager = event.body.data;
              console.log(this.fileManager);
              this.addForm.patchValue({
                file_manager_id: this.fileManager.id
              });
            }
          }
        },
        err => {
          this.progressInfos[idx].value = 0;
          console.log(err);
          this.fileManager = "";
        });
    }
  
    editelectFiles(event) {
      let idx = 0;
      this.progressInfos = [];
      let file = event.target.files[0];
      console.log(file);
      this.fileName = file.name;
      this.progressInfos[idx] = { value: 0, fileName: file.name };
      var formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('type', "important_person");
  
      this.uploadService.upload(formData).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            console.log(event);
            if(event.body.code === "success" && event.body.data){
              this.fileManager = event.body.data;
              console.log(this.fileManager);
              this.editForm.patchValue({
                file_manager_id: this.fileManager.id
              });
            }
          }
        },
        err => {
          this.progressInfos[idx].value = 0;
          console.log(err);
          this.fileManager = "";
        });
    }
  
    getAllImportantPerson(){
      $("#important_person_table").DataTable().clear().destroy();
      this.importantPersonList = [];
      this.importantPersonService.getImportantPerson().subscribe(data=>{
        this.importantPersonList = data;
        console.log(data);
        setTimeout(() => {
          $('#important_person_table').DataTable({
          });
        }, 10);
      });
    }
  
    addImportantPerson(data){
      let value = data;
      console.log(value);
      this.submitted_add = true;
      if(this.addForm.invalid){
        return;
      }
      console.log(value);
      this.importantPersonService.addImportantPerson(value).subscribe(data => {
        this.submitted_add = false;
        $('#modal-add').modal('hide');
        this.getAllImportantPerson();
        this.successDialog();
      }, error => {
        $('#modal-add').modal('hide');
        this.failDialog(error);
      });
    }
  
    openEditImportantPerson(id){
      this.progressInfos = [];
      this.currentId = id;
      this.importantPersonService.getImportantPersonById(id).subscribe(data => {
        this.importantPerson = data;
        console.log(data);
        this.fileName = 'Choose file';
        this.fileManager = {url:this.importantPerson['url']};
        if(this.importantPerson['name_display']){
          this.fileName = this.importantPerson['name_display'];
        }
        this.editForm.patchValue({
          id: this.importantPerson['id'],
          position: this.importantPerson['position'],
          car_registration: this.importantPerson['car_registration'],
          file_manager_id: this.importantPerson['file_manager_id'],
          file: ""
        });
        $('#modal-edit').modal('show');
      });
    }
  
    updateImportantPerson(value){
      this.submitted_edit = true;
      if(this.editForm.invalid){
        return;
      }
      console.log(value);
      this.importantPersonService.updateImportantPerson(value.id, value).subscribe(data => {
        this.submitted_edit = false;
        $('#modal-edit').modal('hide');
        this.getAllImportantPerson();
        this.successDialog();
      }, error => {
        $('#modal-edit').modal('hide');
        this.failDialog(error);
      });
    }
  
    openDeleteImportantPerson(id){
      this.currentId = id;
      $('#modal-remove').modal('show');
    }
  
    deleteImportantPerson(){
      this.importantPersonService.deleteImportantPerson(this.currentId).subscribe(data => {
        $('#modal-remove').modal('hide');
        this.successDialog();
        this.getAllImportantPerson();
      }, error => {
        $('#modal-edit').modal('hide');
        this.failDialog(error);
      });
    }
  
    successDialog(){
      Swal.fire("ทำรายการสำเร็จ!", "", "success");
    }
  
    failDialog(msg){
      Swal.fire({
        type: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: msg
      })
    }
  
  }
