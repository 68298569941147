import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, timeout } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class CardVisitorService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private constant: Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.set('customer_id', localStorage.getItem('customer_id'));
    this.httpOptions.headers = headers;
  }

  getCardVisitor(): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/card-visitor', this.httpOptions);
  }

  getCardVisitorById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/card-visitor/' + id, this.httpOptions);
  }

  updateCardVisitor(id, data): Observable<any> {
    return this.http.put<any>(this.constant.API_ENDPOINT + '/card-visitor/' + id, JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addCardVisitor(data): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/card-visitor', JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteCardVisitor(id): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + '/card-visitor/' + id, {},
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteCardVisitorAll(data): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + '/card-visitor/delete/all', JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  createCardVisitorPdf_old(data): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/card-visitor/create-pdf', JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      timeout(180000), // 1000 = 1s, 180000 = 180s = 3 min
      catchError(this.handleError)
    );
  }

  createCardVisitorPdf(data): Observable<any> {
    return this.http.post<any>(this.constant.API_REPORT_ENDPOINT + '/comvisitor/create-card-visitor-report', JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      timeout(180000), // 1000 = 1s, 180000 = 180s = 3 min
      catchError(this.handleError)
    );
  }

  createCardRegisterReport(data): Observable<any> {
    return this.http.post<any>(this.constant.API_REPORT_ENDPOINT + '/comvisitor/create-card-register-report', JSON.stringify(data),
    this.httpOptions).pipe(
      retry(1),
      timeout(180000), // 1000 = 1s, 180000 = 180s = 3 min
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
  console.log(error);
  let errorMessage = '';
  if(error.error && error.error.data) {
    errorMessage = error.error.data.error_message;
  }
  return throwError(errorMessage);
  }
}