import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class VisitorTypeService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private constant: Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
  }

  // Get visitor type list
  getVisitorType(): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/visitor-type', this.httpOptions);
  }

  // Get visitor type by id
  getVisitorTypeById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/visitor-type/' + id, this.httpOptions);
  }

  // Update visitor type by id
  updateVisitorType(id, visitorType): Observable<any> {
    return this.http.put<any>(this.constant.API_ENDPOINT + '/visitor-type/' + id, JSON.stringify(visitorType),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Add visitor type
  addVisitorType(visitorType): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/visitor-type', JSON.stringify(visitorType),
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Delete visitor type by id
  deleteVisitorType(id): Observable<any> {
    return this.http.patch<any>(this.constant.API_ENDPOINT + '/visitor-type/' + id, {},
    this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  } 

  // Error handling
  handleError(error) {
    console.log(error);
    let errorMessage = '';
    if(error.error && error.error.data) {
      errorMessage = error.error.data.error_message;
    }
    return throwError(errorMessage);
    }
}