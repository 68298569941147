import { CommonService } from './../shared/service/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BacklistService} from '../shared/service/backlist.service';
import { ThrowStmt } from '@angular/compiler';
declare var jquery : any;
declare var $: any;
declare var Swal :any;



@Component({
  selector: 'app-backlist',
  templateUrl: './backlist.component.html',
  styleUrls: ['./backlist.component.css']
})
export class BacklistComponent implements OnInit {


   backlistList = new Array();
   backlist = {
    "status":""
   ,"car_no":""
   ,"first_name":""
   ,"last_name":""
   ,"first_name_en":""
   ,"last_name_en":""
   ,"register_no":""
   ,"expiry_date":""
  };

   addForm :FormGroup;
   editForm :FormGroup;

   submitted_add = false;
   submitted_edit = false;
   currentBacklistId;
   expiryDate;
   options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  };


  constructor(private fb: FormBuilder,
    private backlistService : BacklistService,
    private commonService : CommonService) {
      this.addForm =fb.group({
          'status' :['',Validators.required],
          'car_no':['',Validators.required],
          'first_name':['',Validators.required],
          'last_name':['',Validators.required],
          'first_name_en':[''],
          'last_name_en':[''],
          'register_no':['',Validators.required]
      });

      this.editForm = fb.group({
        'status' :['',Validators.required],
        'car_no':['',Validators.required],
        'first_name':['',Validators.required],
        'last_name':['',Validators.required],
        'first_name_en':[''],
        'last_name_en':[''],
        'register_no':['',Validators.required]
    });
  }

  ngOnInit() {

    this.expiryDate = new Date();
    this.expiryDate.setHours(0);
    this.expiryDate.setMinutes(0);
    this.expiryDate.setSeconds(0);
    this.getAllBacklist();
  }

  getAllBacklist()
  {
     $('#backlist_table').DataTable().clear().destroy();
     this.backlistList = [];
     this.backlistService.getBacklist().subscribe(data=>{
      this.backlistList =data;
      setTimeout(()=>{
        $('#backlist_table').DataTable({

        });
      },10);
     });
  }

  selectedExpiryDate(value:any ,datepicker?:any)
  {
    this.expiryDate = value.start._d;
    console.log(value);
  }

  openAddBacklist()
  {
    this.addForm.patchValue({
       status:""
      ,first_name:""
      ,last_name:""
      ,car_no :""
      ,register_no:""
      ,expiry_date:""
    });
    $('#modal-backlist-add').modal('show');
  }

  addBacklist(input)
  {
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
      let formData =
      {
        "status":input["status"]
        ,"car_no":input["car_no"]
        ,"first_name": input["first_name"]
        ,"last_name":input["last_name"]
        ,"first_name_en": input["first_name_en"]
        ,"last_name_en":input["last_name_en"]
        ,"register_no":input["register_no"]
        ,"expiry_date": this.commonService.convertDateToStrng(this.expiryDate)
      }
      console.log(formData);
      this.backlistService.addBacklist(formData).subscribe(res=>{
        this.successDialog();
        $('#modal-backlist-add').modal('hide');
        this.getAllBacklist();
      },
      error =>{
        $('#modal-backlist-add').modal('hide');
        this.failDialog(error);
      });
  }

  openEditBacklist(backlistid)
  {
      this.currentBacklistId = backlistid;
      this.backlistService.getBacklistById(backlistid).subscribe(res=>{
        console.log(res);
        console.log(this.commonService.convertDateToStrng(res['expiry_date']));
        this.editForm.patchValue({
            status:res["status"]
           ,first_name:res["first_name"]
           ,last_name:res['last_name']
           ,first_name_en:res["first_name_en"]
           ,last_name_en:res['last_name_en']
           ,car_no :res['car_no']
           ,register_no:res['register_no']
           ,expiry_date: this.commonService.convertDateToStrng(res['expiry_date'])
         });
          $('#modal-backlist-edit').modal('show');
      },
      error=>{
        this.failDialog(error);
      });
  }


  updateBacklist(input)
  {
    this.submitted_edit = true;
    if(this.editForm.invalid){
      console.log('validate-edit-fail');
      return;
    }
    let formData =
    {
      "status":input["status"]
      ,"car_no":input["car_no"]
      ,"first_name": input["first_name"]
      ,"last_name":input["last_name"]
      ,"first_name_en": input["first_name_en"]
      ,"last_name_en":input["last_name_en"]
      ,"register_no":input["register_no"]
      ,"expiry_date": this.commonService.convertDateToStrng(this.expiryDate)
    }
    console.log(formData);
    this.backlistService.updateBacklist(this.currentBacklistId,formData).subscribe(res=>{
      this.successDialog();
      $('#modal-backlist-edit').modal('hide');
      this.getAllBacklist();
    },
    error =>{
      $('#modal-backlist-edit').modal('hide');
      this.failDialog(error);
    });
  }

  openDeleteBacklist(backlistid)
  {
      this.currentBacklistId = backlistid;
      console.log(backlistid);
      $('#modal-delete-backlist').modal('show');
  }

  deleteBacklist()
  {
      this.backlistService.deleteBacklist(this.currentBacklistId).subscribe(res=>{
        $('#modal-delete-backlist').modal('hide');
        this.successDialog();
        this.getAllBacklist();
      },
      error => {
        $('#modal-delete-backlist').modal('hide');
        this.failDialog(error);
      });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }



}
