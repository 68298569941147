import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { CustomerComponent } from './customer.component';
import {
  EmployeeService
} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [CustomerComponent],
  exports: [
    CustomerComponent
  ],
  providers: [
    EmployeeService
  ]
})
export class CustomerModule { }
