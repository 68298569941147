import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BackgroundPatternVisitorComponent } from './background-pattern-visitor.component';
import {
  
} from '../../shared';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [BackgroundPatternVisitorComponent],
  exports: [
    BackgroundPatternVisitorComponent
  ],
  providers: [
    
  ]
})
export class BackgroundPatternVisitorModule { }
