import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { CardTypeService } from 'src/app/shared';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-card-type',
  templateUrl: './card-type.component.html',
  styleUrls: ['./card-type.component.css']
})
export class CardTypeComponent implements OnInit { 

cardTypeList = new Array();
cardType = {"name":"","description":""};
addForm: FormGroup;
editForm: FormGroup;
submitted_add = false;
submitted_edit = false;
currentDepartmentId;

  constructor(private fb: FormBuilder,
    private cardTypeService: CardTypeService) { 
    this.addForm = fb.group({
      'name': ['', Validators.required],
      'description': ['']
    });

    this.editForm = fb.group({
      'name': ['', Validators.required],
      'description': [''],
      'id': ['', Validators.required]
    });
  }

  ngOnInit() { 
    this.getAllCardType();
  }

  getAllCardType(){
    $("#card_type_table").DataTable().clear().destroy();
    this.cardTypeList = [];
    this.cardTypeService.getCardType().subscribe(data=>{
      this.cardTypeList = data;
      setTimeout(() => {
        $('#card_type_table').DataTable({
        });
      }, 10);
    });
  }

  openAddCardType(){
    this.addForm.patchValue({
      name: "",
      description: ""
    });
    $('#modal-add').modal('show');
  }

  addCardType(value){
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    console.log(value);
    this.cardTypeService.addCardType(value).subscribe(data => {
      this.submitted_add = false;
      $('#modal-add').modal('hide');
      this.getAllCardType();
      this.successDialog();
    }, error => {
      $('#modal-add').modal('hide');
      this.failDialog(error);
    });
  }

  openEditCardType(id){
    this.currentDepartmentId = id;
    this.cardTypeService.getCardTypeById(id).subscribe(data => {
      this.cardType = data;
      this.editForm.patchValue({
        id: this.cardType['id'],
        name: this.cardType['name'],
        description: this.cardType['description']
      });
      $('#modal-edit').modal('show');
    });
  }

  updateCardType(value){
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    console.log(value);
    this.cardTypeService.updateCardType(value.id, value).subscribe(data => {
      this.submitted_edit = false;
      $('#modal-edit').modal('hide');
      this.getAllCardType();
      this.successDialog();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  openDeleteCardType(id){
    this.currentDepartmentId = id;
    $('#modal-remove').modal('show');
  }

  deleteCardType(){
    this.cardTypeService.deleteCardType(this.currentDepartmentId).subscribe(data => {
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.getAllCardType();
    }, error => {
      $('#modal-edit').modal('hide');
      this.failDialog(error);
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
