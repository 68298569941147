import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient, private constant: Constant) { 
    
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
 
  
  // Get customer list
  getCustomer(): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/customer');
  }

  // Get customer by id
  getCustomerById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_ENDPOINT + '/customer/' + id);
  }

  // Update customer by id
  updateCustomer(id, company): Observable<any> {
    return this.http.put<any>(this.constant.API_ENDPOINT + '/customer/' + id, JSON.stringify(company),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Add customer
  addCustomer(company): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/customer', JSON.stringify(company),
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Delete customer by id
  deleteCustomer(id): Observable<any> {
    return this.http.delete<any>(this.constant.API_ENDPOINT + '/customer/' + id,
    {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-User': 'test'
      })
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  validateCustomer(id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    headers = headers.set("Authorization", "Bearer " + localStorage.getItem("access_token"));
    this.httpOptions.headers = headers;
    return this.http.get(this.constant.API_ENDPOINT + "/customer/" + id , this.httpOptions);
  }

  // Error handling
  handleError(error) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
  // Get client-side error
  errorMessage = error.error.message;
  } else {
  // Get server-side error
  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  //window.alert(errorMessage);
  return throwError(errorMessage);
  }
}