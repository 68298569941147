import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ContactInformationVipComponent } from './contact-information-vip.component';
import { Daterangepicker } from 'ng2-daterangepicker';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Daterangepicker
  ],
  declarations: [ContactInformationVipComponent],
  exports: [
    ContactInformationVipComponent
  ],
  providers: [
    
  ]
})
export class ContactInformationVipModule { }
