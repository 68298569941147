import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup ,Validators } from '@angular/forms';
import { CompanyContractService } from './../../shared/service/company-contract.service';
declare var jquery :any;
declare var $:any;
declare var Swal:any;

@Component({
  selector: 'app-company-contract',
  templateUrl: './company-contract.component.html',
  styleUrls: ['./company-contract.component.css']
})
export class CompanyContractComponent implements OnInit {

  addForm: FormGroup;
  submitted_add = false;
  editForm : FormGroup;
  submitted_edit =false;
  companyContactList =[];
  currentCompanyId;
  companyContact ={"company_name":"","company_desc":""};

  constructor(
      private fb: FormBuilder,
      private compService : CompanyContractService
  ) {

    this.addForm = fb.group({
      'company_name': ['', Validators.required],
      'company_desc': [''],
    });
    this.editForm = fb.group({
      'company_name': ['', Validators.required],
      'company_desc': [''],
      'id': ['', Validators.required],
    });
  }


  ngOnInit() {
    this.getCompanyContact();
  }

  getCompanyContact(){
      this.companyContactList = [];
      $("#company_contact_table").DataTable().clear().destroy();
      this.compService.getCompContact().subscribe(data=>{
        this.companyContactList = data;
        console.log(this.companyContactList);
        setTimeout(() => {
          $('#company_contact_table').DataTable({
          });
        }, 10);
      });
  }

  saveCompanyContact(value){
    console.log(value);
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    this.compService.addCompContact(value).subscribe(res=>{
        $('#companycontact_modal_add').modal('hide');
        this.successDialog();
        this.getCompanyContact();
    }, error => {
      console.log(error);
      $('#companycontact_modal_add').modal('hide');
      this.failDialog(error);
    });
   }

   updateCompanyContact(value){
      this.submitted_edit = true;
      if(this.editForm.invalid){
        return;
      }

      this.compService.updateCompContact(this.currentCompanyId, value).subscribe(res=>{
        $('#companycontact_modal_edit').modal('hide');
          this.successDialog();
          this.getCompanyContact();
      }, error => {
        console.log(error);
        $('#companycontact_modal_edit').modal('hide');
        this.failDialog(error);
      });

   }


    openModelEdit(companyid)
    {
          this.currentCompanyId = companyid;
          console.log(this.currentCompanyId);
          this.compService.getCompContactById(companyid).subscribe(data=>{
              //console.log(data);
              this.companyContact = data;
              console.log(this.companyContact)
              this.editForm.patchValue({
                id: this.companyContact['id'],
                company_name: this.companyContact['name'],
                company_desc: this.companyContact['description']
              });
              $('#companycontact_modal_edit').modal('show');
          });
    }

    openModelDelete(companyid)
    {
      this.currentCompanyId = companyid;
      console.log(this.currentCompanyId);
      $('#companycontact_modal_delete').modal('show');
    }

    deleteComapnyContact(){
      this.compService.deleteCompContact(this.currentCompanyId).subscribe(res=>{
        $('#companycontact_modal_delete').modal('hide');
        this.successDialog();
        this.getCompanyContact();
      }, error => {
        console.log(error);
        $('#companycontact_modal_delete').modal('hide');
        this.failDialog(error);
      });
    }



   successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }


}
