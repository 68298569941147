import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { CustomerService } from '../service/customer.service';
import {ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(
    private router: Router,
    private authService: AuthService,
    private customerService: CustomerService,
  ) { }
 
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   if (!this.authService.isLoggedOnApp()) {
  //     console.log('no login');
  //     this.router.navigate(['/dashboard']);
  //     return false;
  //   } else {
  //     console.log('yes login');
  //     return true;
  //   }
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var customerId = localStorage.getItem("customer_id");
    console.log(customerId);
    this.customerService.validateCustomer(customerId).subscribe(response => {
      console.log('auth guards');
      // console.log(response);
    }, error => {
      console.error(error);
      if(error.error.code == "token_expired"){
        this.authService.forceLogout();
      }
      return false;
    });
    return true;
  }
 
}