import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'login-init',
  templateUrl: './login.component.html'
})
export class LoginInitComponent implements OnInit {

  loginForm: FormGroup;
  submitted_login = false;

  constructor(
    private fb: FormBuilder,
    private authenService: AuthService
  ) {
    this.loginForm = fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });
   }

  ngOnInit() {
  } 

  login(value){ 
    $('.loading').show();
    console.log(value);
    this.submitted_login = true;
    if(this.loginForm.invalid){
      return;
    }
    let param = {'username':value['username'],'password':value['password']};
    this.authenService.auth(param).subscribe(res=>{
        console.log(res.data);
        this.authenService.setCustomer(res.data);
        setTimeout(() => {$('.loading').hide();}, 100);
    }, error => {
      $('.loading').hide();
      console.log(error);
      //let msg = error.split(" ")[2];
      //let code = msg.split(/\n/)[0];
      this.failDialog("Invalid Username and Password");
    });
  }

  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
