import { Component, OnInit } from '@angular/core';
import { IEmployee } from 'ng2-org-chart/src/employee';
import { ContactInformationVipService, UploadFilesService, CommonService } from 'src/app/shared';
import { HttpEventType, HttpResponse } from '@angular/common/http';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-contact-information-vip',
  templateUrl: './contact-information-vip.component.html',
  styleUrls: ['./contact-information-vip.component.css']
})
export class ContactInformationVipComponent implements OnInit { 

  contactInformationVipList = new Array();
  contactInformationVip = {"name":"","description":""};
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  currentId;
  accessToken;
  fileName = 'Choose file';
  
    constructor(private fb: FormBuilder,
      private uploadService: UploadFilesService,
      private commonService: CommonService,
      private contactInformationVipService: ContactInformationVipService) { 
      this.addForm = fb.group({
        'first_name': ['', Validators.required],
        'last_name': ['', Validators.required],
        'gender': ['', Validators.required],
        'car_registration': ['', Validators.required],
        'time_in': ['', Validators.required],
        'time_out': ['', Validators.required],
        'email': ['', Validators.required],
        'mobile': [''],
        'file': [''],
        'file_manager_id': ['']
      });
  
      this.editForm = fb.group({
        'first_name': ['', Validators.required],
        'last_name': ['', Validators.required],
        'gender': ['', Validators.required],
        'car_registration': ['', Validators.required],
        'time_in': ['', Validators.required],
        'time_out': ['', Validators.required],
        'email': ['', Validators.required],
        'mobile': [''],
        'file': [''],
        'file_manager_id': [''],
        'id': ['', Validators.required]
      });
  
      this.accessToken = 'Bearer ' + localStorage.getItem('access_token');
    }
  
    ngOnInit() { 
      this.getAllContactInformationVip();
    }
  
    openAddContactInformationVip(){
      this.fileManager = "";
      this.progressInfos = [];
      this.fileName = 'Choose file';
      this.addForm.patchValue({
        first_name: "",
        last_name: "",
        gender: "",
        car_registration: "",
        time_in: this.commonService.convertDateToStrng(new Date()),
        time_out: this.commonService.convertDateToStrng(new Date()),
        email: "",
        mobile: "",
        file: "",
        file_manager_id: ""
      });
      $('#modal-add').modal('show');
    }

  daterange: any = {};
  startDate;
  endDate;
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };
  selectedDateAdd(value: any, datepicker?: any) {
    // this is the date  selected
    console.log(value);
 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.startDate = value.start._d;
    this.endDate = value.end._d;

    console.log(this.startDate);
    console.log(this.endDate);
    this.addForm.patchValue({
      time_in: this.commonService.convertDateToStrng(this.startDate),
      time_out: this.commonService.convertDateToStrng(this.endDate)
    });
  }
  selectedDateEdit(value: any, datepicker?: any) {
    // this is the date  selected
    console.log(value);
 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.startDate = value.start._d;
    this.endDate = value.end._d;

    console.log(this.startDate);
    console.log(this.endDate);
    this.editForm.patchValue({
      time_in: this.commonService.convertDateToStrng(this.startDate),
      time_out: this.commonService.convertDateToStrng(this.endDate)
    });
  }

    fileManager;
    progressInfos = [];
    addSelectFiles(event) {
      let idx = 0;
      this.progressInfos = [];
      let file = event.target.files[0];
      console.log(file);
      this.fileName = file.name;
      this.progressInfos[idx] = { value: 0, fileName: file.name };
      var formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('type', "contact_information_vip");
  
      this.uploadService.upload(formData).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            console.log(event);
            if(event.body.code === "success" && event.body.data){
              this.fileManager = event.body.data;
              console.log(this.fileManager);
              this.addForm.patchValue({
                file_manager_id: this.fileManager.id
              });
            }
          }
        },
        err => {
          this.progressInfos[idx].value = 0;
          console.log(err);
          this.fileManager = "";
        });
    }
  
    editelectFiles(event) {
      let idx = 0;
      this.progressInfos = [];
      let file = event.target.files[0];
      console.log(file);
      this.fileName = file.name;
      this.progressInfos[idx] = { value: 0, fileName: file.name };
      var formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('type', "contact_information_vip");
  
      this.uploadService.upload(formData).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            console.log(event);
            if(event.body.code === "success" && event.body.data){
              this.fileManager = event.body.data;
              console.log(this.fileManager);
              this.editForm.patchValue({
                file_manager_id: this.fileManager.id
              });
            }
          }
        },
        err => {
          this.progressInfos[idx].value = 0;
          console.log(err);
          this.fileManager = "";
        });
    }
  
    getAllContactInformationVip(){
      $("#contact_information_vip_table").DataTable().clear().destroy();
      this.contactInformationVipList = [];
      this.contactInformationVipService.getContactInformationVip().subscribe(data=>{
        this.contactInformationVipList = data;

        this.contactInformationVipList.forEach(element => {
          QRCode.toDataURL(element['code'], { errorCorrectionLevel: 'M' })
          .then(url => {
            element['qrCodeUrl'] = url;
          })
          .catch(err => {
            console.error(err);
          });
        });

        console.log(data);
        setTimeout(() => {
          $('#contact_information_vip_table').DataTable({
          });
        }, 10);
      });
    }
  
    addContactInformationVip(data){
      let value = data;
      console.log(value);
      this.submitted_add = true;
      if(this.addForm.invalid){
        return;
      }
      console.log(value);
      $('.loading').show();
      this.contactInformationVipService.addContactInformationVip(value).subscribe(data => {
        this.submitted_add = false;
        $('.loading').hide();
        $('#modal-add').modal('hide');
        this.getAllContactInformationVip();
        this.successDialog();
      }, error => {
        $('.loading').hide();
        $('#modal-add').modal('hide');
        this.failDialog(error);
      });
    }
  
    openEditContactInformationVip(id){
      this.progressInfos = [];
      this.currentId = id;
      this.contactInformationVipService.getContactInformationVipById(id).subscribe(data => {
        this.contactInformationVip = data;
        console.log(data);
        this.fileName = 'Choose file';
        this.fileManager = {url:this.contactInformationVip['url']};
        if(this.contactInformationVip['name_display']){
          this.fileName = this.contactInformationVip['name_display'];
        }
        this.editForm.patchValue({
          id: this.contactInformationVip['id'],
          first_name: this.contactInformationVip['first_name'],
          last_name: this.contactInformationVip['last_name'],
          gender: this.contactInformationVip['gender'],
          car_registration: this.contactInformationVip['car_registration'],
          email: this.contactInformationVip['email'],
          mobile: this.contactInformationVip['mobile'],
          time_in: this.contactInformationVip['time_in'],
          time_out: this.contactInformationVip['time_out'],
          file_manager_id: this.contactInformationVip['file_manager_id'],
          file: ""
        });
        $('#modal-edit').modal('show');
      });
    }
  
    updateContactInformationVip(value){
      this.submitted_edit = true;
      if(this.editForm.invalid){
        return;
      }
      console.log(value);
      $('.loading').show();
      this.contactInformationVipService.updateContactInformationVip(value.id, value).subscribe(data => {
        this.submitted_edit = false;
        $('.loading').hide();
        $('#modal-edit').modal('hide');
        this.getAllContactInformationVip();
        this.successDialog();
      }, error => {
        $('.loading').hide();
        $('#modal-edit').modal('hide');
        this.failDialog(error);
      });
    }
  
    openDeleteContactInformationVip(id){
      this.currentId = id;
      $('#modal-remove').modal('show');
    }
  
    deleteContactInformationVip(){
      this.contactInformationVipService.deleteContactInformationVip(this.currentId).subscribe(data => {
        $('#modal-remove').modal('hide');
        this.successDialog();
        this.getAllContactInformationVip();
      }, error => {
        $('#modal-edit').modal('hide');
        this.failDialog(error);
      });
    }

    sendEmail(id){
      $('.loading').show();
      this.contactInformationVipService.sendEmail(id).subscribe(data => {
        $('.loading').hide();
        this.successDialog();
      }, error => {
        $('.loading').hide();
        this.failDialog(error);
      });
    }
  
    successDialog(){
      Swal.fire("ทำรายการสำเร็จ!", "", "success");
    }
  
    failDialog(msg){
      Swal.fire({
        type: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: msg
      })
    }
  
  }
