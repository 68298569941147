import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardServicechargeComponent } from './dashboard-servicecharge.component';
import { DashboardServicechargeService } from '../shared/service/dashboard-servicecharge.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DashboardServicechargeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    DashboardServicechargeComponent
  ]
  ,
  providers:[
    DashboardServicechargeService
  ]
})
export class DashboardServicechargeModule { }
